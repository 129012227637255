import React from 'react';

const ResetPassword = () => {
    return (
        <div className="layout-sticky-subnav layout-default ">
            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout">


                <div id="header" className="mdk-header js-mdk-header mb-0" data-fixed data-effects="">
                    <div className="mdk-header__content">



                        <div className="navbar navbar-expand navbar-dark-dodger-blue navbar-shadow" id="default-navbar">


                            <a href="index.html" className="navbar-brand mr-16pt">
                                <img src="assets/images/logo/logo.png" alt="logo" className="img-fluid" />
                        </a>



                        <ul className="nav navbar-nav ml-auto mr-0">
                            <a href="index.html" className="">
                                <img src="assets/images/logo/goi.png" style={{width:'75%'}} className="img-fluid" alt="logo" />
                            </a>
                            <a href="index.html" className="">
                                <img src="assets/images/logo/mamta.png" style={{width:'75%'}} className="img-fluid" alt="logo" />
                            </a>
                            <a href="index.html" className="">
                                <img src="assets/images/logo/nhm.png" style={{width:'75%'}} className="img-fluid" alt="logo" />
                            </a>
                            <li className="nav-item">
                                <a href="fixed-login.html" className="nav-link" data-toggle="tooltip" data-title="Login" data-placement="bottom" data-boundary="window"><i className="material-icons">lock_open</i></a>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>


            <div className="mdk-header-layout__content page-content ">


                <div className="page-section pb-0">
                    <div className="container page__container d-flex flex-column flex-sm-row align-items-sm-center">
                        <div className="flex">
                            <h1 className="h2 mb-0">Reset Password</h1>
                            <p className="text-breadcrumb">Account Management</p>
                        </div>
                        <p className="d-sm-none"></p>
                        <a href="" className="btn btn-outline-secondary flex-column">
                            Need Help?
                            <span className="btn__secondary-text">Contact us</span>
                        </a>
                    </div>
                </div>

                <div className="page-section">
                    <div className="container page__container">
                        <div className="page-separator">
                            <div className="page-separator__text">Reset Password</div>
                        </div>
                        <div className="col-sm-6 p-0">
                            <div className="alert alert-soft-warning">
                                <div className="d-flex flex-wrap">
                                    <div className="mr-8pt">
                                        <i className="material-icons">check_circle</i>
                                    </div>
                                    <div className="flex" style={{minWidth: 180}}>
                                        <small className="text-black-100">
                                            An email with password reset instructions has been sent to your email address, if it exists on our system.
                                        </small>
                                    </div>
                                </div>
                            </div>

                            <form action="fixed-change-password.html">
                                <div className="form-group">
                                    <label className="form-label">Email:</label>
                                    <input type="text" className="form-control" placeholder="Your email address ..."/>
                                        <small className="form-text text-muted">We will email you with info on how to reset your password.</small>
                                </div>
                                <button className="btn btn-primary">Reset</button>
                            </form>
                        </div>
                    </div>
                </div>




            </div>

            <footer>

                <div className="js-fix-footer bg-white border-top-2" data-domfactory-upgraded="fix-footer" style={{opacity: 1}}>
                    <div className="container page-section py-lg-48pt">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                        <h4 className="text-70">Quick Link</h4>
                                        <nav className="nav nav-links nav--flush flex-column">
                                            <a className="nav-link" href="#">Terms & Conditions</a>
                                            <a className="nav-link" href="#">Privacy Policy</a>

                                        </nav>
                                    </div>
                                    <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                        <h4 className="text-70">Courses</h4>
                                        <nav className="nav nav-links nav--flush flex-column">
                                            <a className="nav-link" href="#">Program 1</a>
                                            <a className="nav-link" href="#">Program 2</a>
                                            <a className="nav-link" href="#">Program 3</a>
                                            <a className="nav-link" href="#">Program 4</a>
                                        </nav>
                                    </div>
                                    <div className="col-6 col-md-4 mb-32pt mb-md-0">
                                        <h4 className="text-70">Community</h4>
                                        <nav className="nav nav-links nav--flush flex-column">
                                            <a className="nav-link" href="#">Discussions</a>
                                            <a className="nav-link" href="#">Ask Question</a>
                                            <a className="nav-link" href="#">Student Profile</a>
                                            <a className="nav-link" href="#">Instructor Profile</a>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-md-right">
                                <p className="text-70 brand justify-content-md-end">
                                    <img className="brand-icon" src="assets/images/logoblack.png" width="200" alt="Tutorio"/>
                                </p>
                                <p className="text-muted mb-0 mb-lg-16pt">eLearning Platform for Medical Officers, ANMs, Peer Educators cover health related education programs with certifications.</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-footer page-section py-lg-32pt">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-2 col-sm-4 mb-24pt mb-md-0">

                                </div>
                                <div className="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center">
                                    <a href="" className="btn btn-outline-white">English <span className="icon--right material-icons">arrow_drop_down</span></a>
                                </div>
                                <div className="col-md-4  text-md-right">

                                    <p className="text-black-50 mb-0">Copyright 2023 © All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>



    <div className="mdk-drawer js-mdk-drawer" id="default-drawer">
        <div className="mdk-drawer__content">
            <div className="sidebar sidebar-dark-dodger-blue sidebar-left" data-perfect-scrollbar>


                <div className="d-flex align-items-center navbar-height">
                    <form action="fixed-index.html" className="search-form search-form--black mx-16pt pr-0 pl-16pt">
                        <input type="text" className="form-control pl-0" placeholder="Search"/>
                            <button className="btn" type="submit"><i className="material-icons">search</i></button>
                    </form>
                </div>



                <a href="index.html" className="sidebar-brand ">
                    <span className="avatar avatar-xl sidebar-brand-icon h-auto">

                        <span className="avatar-title rounded bg-primary"><img src="assets/images/logo/logo.png" className="img-fluid" alt="logo" /></span>

                    </span>

                </a>






                <div className="sidebar-heading">Applications</div>
                <ul className="sidebar-menu">

                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button js-sidebar-collapse" data-toggle="collapse" href="#student_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">school</span>
                            Student
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="student_menu">


                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-index.html">

                                    <span className="sidebar-menu-text">Home</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-courses.html">

                                    <span className="sidebar-menu-text">Browse Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-paths.html">

                                    <span className="sidebar-menu-text">Browse Paths</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-dashboard.html">

                                    <span className="sidebar-menu-text">Student Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-my-courses.html">

                                    <span className="sidebar-menu-text">My Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-paths.html">

                                    <span className="sidebar-menu-text">My Paths</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-path.html">

                                    <span className="sidebar-menu-text">Path Details</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-course.html">

                                    <span className="sidebar-menu-text">Course Preview</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-lesson.html">

                                    <span className="sidebar-menu-text">Lesson Preview</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-take-course.html">

                                    <span className="sidebar-menu-text">Take Course</span>
                                    <span className="sidebar-menu-badge badge badge-accent badge-notifications ml-auto">PRO</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-take-lesson.html">

                                    <span className="sidebar-menu-text">Take Lesson</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-take-quiz.html">

                                    <span className="sidebar-menu-text">Take Quiz</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-quiz-results.html">

                                    <span className="sidebar-menu-text">My Quizzes</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-quiz-result-details.html">

                                    <span className="sidebar-menu-text">Quiz Result</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-path-assessment.html">

                                    <span className="sidebar-menu-text">Skill Assessment</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-path-assessment-result.html">

                                    <span className="sidebar-menu-text">Skill Result</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button js-sidebar-collapse" data-toggle="collapse" href="#instructor_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">format_shapes</span>
                            Instructor
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="instructor_menu">


                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-dashboard.html">

                                    <span className="sidebar-menu-text">Instructor Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-courses.html">

                                    <span className="sidebar-menu-text">Manage Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-quizzes.html">

                                    <span className="sidebar-menu-text">Manage Quizzes</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-earnings.html">

                                    <span className="sidebar-menu-text">Earnings</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-statement.html">

                                    <span className="sidebar-menu-text">Statement</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-edit-course.html">

                                    <span className="sidebar-menu-text">Edit Course</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-edit-quiz.html">

                                    <span className="sidebar-menu-text">Edit Quiz</span>
                                </a>
                            </li>

                        </ul>
                    </li>



                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button js-sidebar-collapse" data-toggle="collapse" href="#enterprise_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">donut_large</span>
                            Enterprise
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="enterprise_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-erp-dashboard.html">
                                    <span className="sidebar-menu-text">ERP Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-crm-dashboard.html">
                                    <span className="sidebar-menu-text">CRM Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-hr-dashboard.html">
                                    <span className="sidebar-menu-text">HR Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-employees.html">
                                    <span className="sidebar-menu-text">Employees</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-staff.html">
                                    <span className="sidebar-menu-text">Staff</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-leaves.html">
                                    <span className="sidebar-menu-text">Leaves</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button disabled" href="fixed-departments.html">
                                    <span className="sidebar-menu-text">Departments</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#productivity_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">access_time</span>
                            Productivity
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="productivity_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-projects.html">
                                    <span className="sidebar-menu-text">Projects</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-tasks-board.html">
                                    <span className="sidebar-menu-text">Tasks Board</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-tasks-list.html">
                                    <span className="sidebar-menu-text">Tasks List</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button disabled" href="fixed-kanban.html">
                                    <span className="sidebar-menu-text">Kanban</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#ecommerce_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">shopping_cart</span>
                            eCommerce
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="ecommerce_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ecommerce.html">
                                    <span className="sidebar-menu-text">Shop Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button disabled" href="fixed-edit-product.html">
                                    <span className="sidebar-menu-text">Edit Product</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#messaging_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">message</span>
                            Messaging
                            <span className="sidebar-menu-badge badge badge-accent badge-notifications ml-auto">2</span>
                            <span className="sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="messaging_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-messages.html">
                                    <span className="sidebar-menu-text">Messages</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-email.html">
                                    <span className="sidebar-menu-text">Email</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#cms_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">content_copy</span>
                            CMS
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="cms_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-cms-dashboard.html">
                                    <span className="sidebar-menu-text">CMS Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-posts.html">
                                    <span className="sidebar-menu-text">Posts</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebar-menu-item active open">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#account_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                            Account
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse show sm-indent" id="account_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-pricing.html">
                                    <span className="sidebar-menu-text">Pricing</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-login.html">
                                    <span className="sidebar-menu-text">Login</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-signup.html">
                                    <span className="sidebar-menu-text">Signup</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-signup-payment.html">
                                    <span className="sidebar-menu-text">Payment</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item active">
                                <a className="sidebar-menu-button" href="fixed-reset-password.html">
                                    <span className="sidebar-menu-text">Reset Password</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-change-password.html">
                                    <span className="sidebar-menu-text">Change Password</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-edit-account.html">
                                    <span className="sidebar-menu-text">Edit Account</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-edit-account-profile.html">
                                    <span className="sidebar-menu-text">Profile &amp; Privacy</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-edit-account-notifications.html">
                                    <span className="sidebar-menu-text">Email Notifications</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-edit-account-password.html">
                                    <span className="sidebar-menu-text">Account Password</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-billing.html">
                                    <span className="sidebar-menu-text">Subscription</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-billing-upgrade.html">
                                    <span className="sidebar-menu-text">Upgrade Account</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-billing-payment.html">
                                    <span className="sidebar-menu-text">Payment Information</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-billing-history.html">
                                    <span className="sidebar-menu-text">Payment History</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-billing-invoice.html">
                                    <span className="sidebar-menu-text">Invoice</span>
                                </a>
                            </li>
                        </ul>
                    </li>


                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#community_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">people_outline</span>
                            Community
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="community_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-teachers.html">

                                    <span className="sidebar-menu-text">Browse Teachers</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-profile.html">

                                    <span className="sidebar-menu-text">Student Profile</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-teacher-profile.html">

                                    <span className="sidebar-menu-text">Teacher Profile</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-blog.html">

                                    <span className="sidebar-menu-text">Blog</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-blog-post.html">

                                    <span className="sidebar-menu-text">Blog Post</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-faq.html">
                                    <span className="sidebar-menu-text">FAQ</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-help-center.html">
                                    <span className="sidebar-menu-text">Help Center</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-discussions.html">
                                    <span className="sidebar-menu-text">Discussions</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-discussion.html">
                                    <span className="sidebar-menu-text">Discussion Details</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-discussions-ask.html">
                                    <span className="sidebar-menu-text">Ask Question</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>


                <div className="sidebar-heading">UI</div>
                <ul className="sidebar-menu">
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#components_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">tune</span>
                            Components
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="components_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-buttons.html">
                                    <span className="sidebar-menu-text">Buttons</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-avatars.html">
                                    <span className="sidebar-menu-text">Avatars</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-forms.html">
                                    <span className="sidebar-menu-text">Forms</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-loaders.html">
                                    <span className="sidebar-menu-text">Loaders</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-tables.html">
                                    <span className="sidebar-menu-text">Tables</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-cards.html">
                                    <span className="sidebar-menu-text">Cards</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-icons.html">
                                    <span className="sidebar-menu-text">Icons</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-tabs.html">
                                    <span className="sidebar-menu-text">Tabs</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-alerts.html">
                                    <span className="sidebar-menu-text">Alerts</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-badges.html">
                                    <span className="sidebar-menu-text">Badges</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-progress.html">
                                    <span className="sidebar-menu-text">Progress</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-pagination.html">
                                    <span className="sidebar-menu-text">Pagination</span>
                                </a>
                            </li>

                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button disabled" href="">
                                    <span className="sidebar-menu-text">Disabled</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#plugins_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">folder</span>
                            Plugins
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="plugins_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-charts.html">
                                    <span className="sidebar-menu-text">Charts</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-flatpickr.html">
                                    <span className="sidebar-menu-text">Flatpickr</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-daterangepicker.html">
                                    <span className="sidebar-menu-text">Date Range Picker</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-dragula.html">
                                    <span className="sidebar-menu-text">Dragula</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-dropzone.html">
                                    <span className="sidebar-menu-text">Dropzone</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-range-sliders.html">
                                    <span className="sidebar-menu-text">Range Sliders</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-quill.html">
                                    <span className="sidebar-menu-text">Quill</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-select2.html">
                                    <span className="sidebar-menu-text">Select2</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-nestable.html">
                                    <span className="sidebar-menu-text">Nestable</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-fancytree.html">
                                    <span className="sidebar-menu-text">Fancy Tree</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-maps-vector.html">
                                    <span className="sidebar-menu-text">Vector Maps</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-sweet-alert.html">
                                    <span className="sidebar-menu-text">Sweet Alert</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-ui-plugin-toastr.html">
                                    <span className="sidebar-menu-text">Toastr</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button disabled" href="">
                                    <span className="sidebar-menu-text">Disabled</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="sidebar-menu-item">
                        <a className="sidebar-menu-button" data-toggle="collapse" href="#layouts_menu">
                            <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">view_compact</span>
                            Layouts
                            <span className="ml-auto sidebar-menu-toggle-icon"></span>
                        </a>
                        <ul className="sidebar-submenu collapse sm-indent" id="layouts_menu">
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="compact-reset-password.html">
                                    <span className="sidebar-menu-text">Compact</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="mini-reset-password.html">
                                    <span className="sidebar-menu-text">Mini</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="mini-secondary-reset-password.html">
                                    <span className="sidebar-menu-text">Mini + Secondary</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="reset-password.html">
                                    <span className="sidebar-menu-text">App</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="boxed-reset-password.html">
                                    <span className="sidebar-menu-text">Boxed</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="sticky-reset-password.html">
                                    <span className="sidebar-menu-text">Sticky</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item active">
                                <a className="sidebar-menu-button" href="fixed-reset-password.html">
                                    <span className="sidebar-menu-text">Fixed</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>


            </div>
        </div>
    </div>
            </div>
        </div>
    );
};

export default ResetPassword;