// const API_ROOT = "http://rkskmamta.local/mamta_admin/api/v1/";
const API_ROOT = "https://elearning.rkskpeereducator.in/mamta_admin_new/api/v1/";
//const API_ROOT2 = "http://rkskmamta.local/mamta_admin/api2/";
// const API_ROOT2 = "http://rkskmamta.local/mamta_admin/api2/";
 const API_ROOT2 = "https://elearning.rkskpeereducator.in/api2_new/";

const API = {
    MIN_SCORE: 60,
    Login: API_ROOT+"Auth/login",
    GetProfile: API_ROOT+"User/userProfile",
    getState: API_ROOT+"Masters/getState",
    getDistrict: API_ROOT+"Masters/getDisctrict",
    getBlocks: API_ROOT+"Masters/getBlocks",
    getEducation: API_ROOT+"Masters/getEducation",
    getDesignation: API_ROOT+"Masters/getDesignation",
    editProfile: API_ROOT+"User/editProfile",
    meeting: API_ROOT+"User/userMeetingSchedule",
    Scores: API_ROOT2+"scores",
    Experts: API_ROOT2+"faq",
    Lessons: API_ROOT2+"lessons",
    Certificate: API_ROOT2+"downloadcertificate.php",
}

export default API;