import React, {useEffect} from 'react';
// import alertify from "alertifyjs";
import jAlert from "../Helpers/jAlert"
import useLessons from "../DataFiles/Lessons";
import {lessonHelper} from "../Helpers/lessonHelpers";
import API from "../Queries/API";



const TestResult = (Prop) => {
    const {data:Lessons, isLoading, error} = useLessons()

    useEffect(()=>{
        console.log({TSProp:Prop})
    },[])
    return (
        <div>


            <div className="card">
               <h3 style={{margin:20}} className="text-center">Your Score is {Prop?.Scores?.percentage}%</h3>
                <div className="card-body text-center"><img
                    style={{height:150,width:150}}
                    src={Prop?.Scores?.percentage < API.MIN_SCORE ? "./assets/images/illustration/tryagain.jpg" : "./assets/images/illustration/trophy.png"}/>
                    <h4>{Prop?.Scores?.percentage < API.MIN_SCORE ? "THANKS FOR ATTEMPT!" : "THANKS FOR ATTEMPT!"}!</h4>
                    {/*<p>You did great!! You rightly answered {Prop?.Scores?.correct} out of {Prop?.Scores?.total} questions./!*, but you need at least 80% to pass this stage*!/</p>*/}
                    {Prop?.testType ==='postTest' && Prop?.Scores?.percentage < API.MIN_SCORE
                        ?
                        <p>You rightly answered {Prop?.Scores?.correct} out of {Prop?.Scores?.total} questions.  Try to achieve {API.MIN_SCORE}% score</p>

                        : <p>You did great!! You rightly answered {Prop?.Scores?.correct} out of {Prop?.Scores?.total} questions.</p>}

                    <button className="btn btn-info btn-square continue"  onClick={()=>{

                       /* if(Prop?.testType==='postTest'){
                            window.location.href="./quiz-score";
                            return;
                        }*/

                        if(Prop?.testType==='postTest'){
                            if(Prop?.Scores?.percentage < API.MIN_SCORE){
                                jAlert(`Sorry, You Need to Score up to ${API.MIN_SCORE}% in the Post Test Before you can Proceed to Lesson<br/> Please take the Post Test Again`)
                                Prop?.setCurPlayer('postTest')
                                // Prop?.setCLI(0)
                            }else{
                                window.location.href="./take-course?module="+(Prop?.curModule+1)
                                // window.location.reload()
                            }
                        }else{

                            //debugger;
                            window.location.href="./take-course?module="+Prop?.curModule

                            // window.location.reload() //TO FORCE RELOAD AFTER PRETEST
                            // Prop?.setCurPlayer('')
                            // Prop?.setCLI(1)
                            // jAlert('Congratulations! First lesson is unlocked');




                            // debugger;
                            const mapLock = lessonHelper.mapLockId(Lessons)
                            mapLock?.sort((a, b) => (a.id > b.id) ? 1: -1)
                            const mapIndex = mapLock.findIndex((oneLock)=>{
                                return Prop.curLockId >= oneLock.minLock && Prop?.curLockId <= oneLock?.maxLock
                            })
                            Prop?.setCLI(Prop?.curLockId+1);
                            let oneMenu;
                            if(mapLock[mapIndex+1]){
                                oneMenu = Lessons[mapIndex+1]?.lessons[0]
                                console.log({oneMenu})
                                Prop?.setCurSubTitle(oneMenu?.Title)
                                Prop?.setCurPlayer(oneMenu?.Type)
                                Prop?.setCurModule(oneMenu?.id)
                                Prop.setCurLesson(oneMenu)
                            }else{
                                window.location.reload();
                            }





                        /*    if(Prop?.curLockId > 17){
                                Prop?.setCLI(19)
                                let oneMenu = Lessons[1]?.lessons[0];
                                console.log({oneMenu})
                                Prop?.setCurSubTitle(oneMenu?.Title)
                                Prop?.setCurPlayer(oneMenu?.Type)
                                Prop?.setCurModule(oneMenu?.id)
                                Prop.setCurLesson(oneMenu)
                                // console.log({Prop})

                            }else{
                                Prop?.setCLI(1)
                                let oneMenu = Lessons[0]?.lessons[0];
                                console.log({oneMenu})
                                Prop?.setCurSubTitle(oneMenu?.Title)
                                Prop?.setCurPlayer(oneMenu?.Type)
                                Prop?.setCurModule(oneMenu?.id)
                                Prop.setCurLesson(oneMenu)
                                // console.log({Prop})
                            }

*/



                        }

                    }}>{Prop?.Scores?.percentage < API.MIN_SCORE && Prop?.testType==='postTest'? 'Go through the module again and retry test' : 'Continue to Next Section'}</button>
                </div>
            </div>



        </div>

    );
};

export default TestResult;