import React from 'react';

const FlipBook = (Prop) => {
    return (
        <div>
            <iframe allowfullscreen="allowfullscreen" scrolling="no" Class="fp-iframe" src={Prop?.curLesson?.URL} style={{border: "1px solid lightgray", width: "100%", height: "800px"}}></iframe>

        </div>
    );
};

export default FlipBook;