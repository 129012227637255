import API from "./API";

const Checker = (callback=()=>{})=>{
    let token = localStorage.usertoken
    if(!token){
        window.location.href = "./";
        return;
    }
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    // myHeaders.append("Cookie", "ci_session=9cfd0d3821911c44332dc8711accc0f9430384b8");


    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    fetch(API.GetProfile, requestOptions)
        .then(response => response.json())
        .then((rsp) => {
            // console.log('during checker')
            // console.log({rsp})
            if(rsp?.status === false){
                localStorage.removeItem('usertoken')
                localStorage.removeItem('userData')
                window.location.href = "./login";
            }else{
                localStorage.setItem('userData',JSON.stringify(rsp?.userData))
                callback(rsp?.userData)
            }
        })
        .catch(error => console.log('error', error));
}
const getProfileInfo = ()=>{
    let isLogged = false;
    if(localStorage.userData){
        return {
            isLogged:true,
            ...JSON.parse(localStorage.userData)
        }
    }
    return {isLogged}
}

export {Checker,getProfileInfo}