import React, {useEffect, useState} from 'react';
import Overlay from "../Components/Overlay";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import $ from "jquery";
import alertify from "alertifyjs";
import API from "../Queries/API";
import {Checker} from "../Queries/Sessions";
import moment from "moment/moment";


const AskExpert = () => {
    const [showMenu, setShowMenu] = useState(false)


    const [Questions, setQuestions] = useState([])


    useEffect(()=>{

        let data = {
            type:'all',
            token: localStorage.usertoken,
            perform:'loadfaqs'
        }

        fetch(API.Experts,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify(data)
        }).then((response)=> response.json())
            .then((rsp)=>{
                console.log({rsp})
                setQuestions(rsp)
            })
            .finally(()=>{
                // setL(false)
            })

    },[])




    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default">
                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>

                <div className="mdk-header-layout js-mdk-header-layout" style={{backgroundColor: '#ffffff'}}>

                    <Header showMenu={setShowMenu}/>



                    <div className="mdk-header-layout__content page-content ">


                        <div className="container page__container">


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="page-section">
                                        <h4>Answer Submitted By Expert</h4>

                                        <div className="card--connect pb-32pt pb-lg-64pt">
                                            <div className="card o-hidden mb-0">
                                                {
                                                    Questions?.map((oneQuestion)=>{
                                                        return (
                                                            <>

                                                            <div className="card-header bg-transparent" style={{padding:5}}>
                                                                <h6 className="m-0 " style={{color:"#000"}}>Date: {oneQuestion?.askdate && moment(oneQuestion?.askdate).format('Do MMM, YY')}</h6>
                                                                <h6 className=" mb-0" style={{color:"#000"}}>TOPIC: {oneQuestion.topic} </h6>
                                                            </div>

                                                        <div className="list-group list-group-flush">
                                                            <div className=" card list-group-item p-3">
                                                                <div className="row align-items-start">
                                                                    <div className="  col-md-9 mb-8pt mb-md-0">
                                                                        <div className="media align-items-center">
                                                                            <div className=" media-left mr-12pt">
                                                                                <h6 style={{fontSize: 17}}>Question:{oneQuestion?.question}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media align-items-center">
                                                                            <div className=" media-left mr-12p">
                                                                                <p><strong>Answer:</strong>
                                                                                    {oneQuestion.answer ||
                                                                                        <div className="text-warning">Awaiting
                                                                                            Reply</div>} </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div
                                                                        className="col-md-3 mb-8pt mb-md-0 d-flex flex-column align-items-center justify-content-center"
                                                                        style={{marginTop:67, paddingLeft: 89}}>
                                                                        <h6 className="m-0">{oneQuestion.expert} <br/> Date:{oneQuestion?.answer_date && moment(oneQuestion?.answer_date).format('Do MMM, YY')}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {/*   <div className="card-header bg-transparent" style={{padding:5}}>
                                                    <h6 className="m-0 " style={{color:"#fff"}}>Date: 6/05/2023</h6>
                                                    <h6 className=" mb-0" style={{color:"#fff"}}>TOPIC: Topic selected
                                                        by user
                                                    </h6>


                                                </div>

                                                <div className="list-group list-group-flush">
                                                    <div className="list-group-item p-3">
                                                        <div className="row align-items-start">
                                                            <div className="col-md-9 mb-8pt mb-md-0">
                                                                <div className="media align-items-center">
                                                                    <div className="media-left mr-12pt">
                                                                        <h6 style={{fontSize:17}}>Question:Lorem
                                                                            ipsum dolor sit amet consectetur
                                                                            adipisicing elit. Labore, laboriosam ?</h6>
                                                                    </div>
                                                                    <div className="media-left mr-12p">
                                                                        <p>Lorem ipsum dolor sit amet consectetur
                                                                            adipisicing
                                                                            elit. Illo ut omnis nihil nam ex rerum error
                                                                            voluptas, quibusdam est ullam quidem
                                                                            incidunt ipsum
                                                                            repellat atque laboriosam illum autem
                                                                            necessitatibus
                                                                            voluptates?</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className=" col-md-3 mb-8pt mb-md-0 d-flex flex-column align-items-center justify-content-center"
                                                                style={{marginTop:67, paddingLeft: 89}}>
                                                                <h6 className="m-0">BY Dr. Abhinav
                                                                    Kumar <br/> Date:6/05/2023</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>*/}
                                                    </>)
                                                    })
                                                }



                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>

                        </div>

                        <Footer/>

                    </div>


                </div>
            </div>



        </div>
    );
};

export default AskExpert;