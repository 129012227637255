import React from 'react';
import HeaderOut from "../Components/HeaderOut";
import Banner from "../Assets/Images/RKSK-banner.jpeg";
import WelcomeBanner from "../Assets/Images/WelcomeBanner.png";
import Footer from "../Components/Footer";
import {Badge, Form, IconContext, Search} from 'react-flaticons';


const Home = () => {
    return (
        <div className="layout-sticky-subnav layout-default ">

            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout">


               {/* <div id="header" className="mdk-header mdk-header--bg-dark bg-dark js-mdk-header mb-0"
                     data-effects="parallax-background waterfall" data-fixed data-condenses>
                    <div className="mdk-header__bg">
                        <div className="mdk-header__bg-front"
                             style={{backgroundImage: "url(assets/images/photodune-4161018-group-of-students-m.jpg)"}}></div>
                    </div>
                    <div className="mdk-header__content justify-content-center">


                        <div className="navbar navbar-expand navbar-dark-dodger-blue bg-transparent will-fade-background"
                             id="default-navbar">


                            <a href="index.html" className="">
                                <img src="assets/images/logo/logo.png" style={{width: '62%'}} className="img-fluid"
                                     alt="logo"/>
                            </a>

                            <ul className="nav navbar-nav ml-auto mr-0">


                                <a href="index.html" className="">
                                    <img src="assets/images/logo/goi.png" style={{width: '75%'}} className="img-fluid"
                                         alt="logo"/>
                                </a>
                                <a href="index.html" className="">
                                    <img src="assets/images/logo/mamta.png" style={{width: '73%'}} className="img-fluid"
                                         alt="logo"/>
                                </a>
                                <a href="index.html" className="">
                                    <img src="assets/images/logo/nhm.png" style={{width: '73%'}} className="img-fluid"
                                         alt="logo"/>
                                </a>

                                <li className="nav-item">
                                    <a href="./login" className="nav-link" data-toggle="tooltip" data-title="Login"
                                       data-placement="bottom" data-boundary="window"><i
                                        className="material-icons">lock_open</i></a>
                                </li>
                            </ul>
                        </div>

                        <div className="hero container page__container text-center text-md-left py-112pt">
                            <h1 className="text-white text-shadow">Course for MO</h1>
                            <p className="lead measure-hero-lead mx-auto mx-md-0 text-white text-shadow mb-48pt">Course
                                Description for Medical Officer will go here </p>

                        </div>
                    </div>
                </div>*/}
                <HeaderOut/>
                <section style={{paddingTop:60}}>
                    <img src={Banner} style={{width:'100%',maxHeight:700}}/>
                    <div className="getStarted" onClick={()=>window.location.href="./login"}>
                    </div>
                </section>



                <div className="mdk-header-layout__content page-content" style={{marginTop:'unset'}}>

                     <div className="bg-white border-bottom-2 py-16pt ">
                        <div className="container page__container">
                            <div className="row align-items-center">
                                <div className="col-md-6 text-right">
                                    <img src={WelcomeBanner} style={{height:200}}/>
                                </div>
                               <div className="col-md-6 align-items-left">
                                   <div style={{fontWeight:'bold',color:'#4c671b',fontSize:15}}>About Us</div>
                                   <div style={{fontWeight:'bold',fontSize:20}}>Welcome to <span style={{color:'#4c671b'}}>eLEARNING</span></div>
                                   <div style={{fontSize:15}}>Peer Education component is one of the key components of Rashtriya Kishor Swasthya Karyakram. To supplement the knowledge of peer educators and to aid them in easy and convenient reporting of their work on monthly basis; this application has been devised under the Government of India. Any adolescent can get access to this application, free of cost and use it for enhancing their knowledge.</div>
                                   {/* <div style={{fontSize:12}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has</div>
                                   <div style={{paddingLeft:20}}>
                                       <ul>
                                           <li>Skilled Instructors</li>
                                           <li>Play Quiz</li>
                                           <li>Certificate</li>
                                       </ul>
                                   </div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-section border-bottom-2">

                        <div className="container page__container ">

                            <div className="page-separator">
                                <div style={{fontWeight:'bold',fontSize:24,color:'#000',backgroundColor:'rgb(246 247 250)'}}><span style={{color:'rgb(77 104 28)'}}>Courses</span> Available</div>
                            </div>


                            <div className="row card-group-row">

                                <div className="col-md-6 col-lg-4 card-group-row__col">

                                    <div className="card card--elevated posts-card-popular overlay card-group-row__card">
                                        <img src="assets/images/paths/sketch_430x168.png" alt="" className="card-img"/>
                                        <div className="fullbleed bg-primary" style={{opacity: 0.5}}></div>
                                        <div className="posts-card-popular__content">
                                            <div className="card-body d-flex align-items-center">
                                                <div className="avatar-group flex">
                                                    <div className="avatar avatar-xs" data-toggle="tooltip"
                                                         data-placement="top" title="Janell D.">
                                                        {/* <a href=""><img
                                                            src="assets/images/256_luke-porter-261779-unsplash.jpg"
                                                            alt="Avatar" className="avatar-img rounded-circle"/></a> */}
                                                    </div>
                                                </div>
                                                {/* <a style={{textDecoration: 'none'}} className="d-flex align-items-center"
                                                   href=""><i className="material-icons mr-1"
                                                              style={{fontSize:'inherit'}}>remove_red_eye</i>
                                                    <small>327</small></a> */}
                                            </div>
                                            <div className="posts-card-popular__title card-body">
                                                <small className="text-muted text-uppercase"></small>
                                                <a className="card-title" href="" style={{fontSize:'small'}}>Training on AFHS for Medical Officers</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* <div className="col-md-6 col-lg-4 card-group-row__col">

                                    <div className="card card--elevated posts-card-popular overlay card-group-row__card">
                                        <img src="assets/images/paths/invision_430x168.png" alt="" className="card-img"/>
                                        <div className="fullbleed bg-primary" style={{opacity:0.5}}></div>
                                        <div className="posts-card-popular__content">
                                            <div className="card-body d-flex align-items-center">
                                                <div className="avatar-group flex">
                                                    <div className="avatar avatar-xs" data-toggle="tooltip"
                                                         data-placement="top" title="Janell D.">
                                                        <a href=""><img
                                                            src="assets/images/256_luke-porter-261779-unsplash.jpg"
                                                            alt="Avatar" className="avatar-img rounded-circle"/></a>
                                                    </div>
                                                </div>
                                                <a style={{textDecoration:'none'}} className="d-flex align-items-center"
                                                   href=""><i className="material-icons mr-1"
                                                              style={{fontSize:'inherit'}}>remove_red_eye</i>
                                                    <small>327</small></a>
                                            </div>
                                            <div className="posts-card-popular__title card-body">
                                                <small className="text-muted text-uppercase">ANM</small>
                                                <a className="card-title" href="fixed-student-course.html">Course for
                                                    ANM</a>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}

                                {/* <div className="col-md-6 col-lg-4 card-group-row__col">

                                    <div className="card card--elevated posts-card-popular overlay card-group-row__card">
                                        <img src="assets/images/paths/invision_430x168.png" alt="" className="card-img"/>
                                        <div className="fullbleed bg-primary" style={{opacity:0.5}}></div>
                                        <div className="posts-card-popular__content">
                                            <div className="card-body d-flex align-items-center">
                                                <div className="avatar-group flex">
                                                    <div className="avatar avatar-xs" data-toggle="tooltip"
                                                         data-placement="top" title="Janell D.">
                                                        <a href=""><img
                                                            src="assets/images/256_luke-porter-261779-unsplash.jpg"
                                                            alt="Avatar" className="avatar-img rounded-circle"/></a>
                                                    </div>
                                                </div>
                                                <a style={{textDecoration:'none'}} className="d-flex align-items-center"
                                                   href=""><i className="material-icons mr-1"
                                                              style={{fontSize:'inherit'}}>remove_red_eye</i>
                                                    <small>327</small></a>
                                            </div>
                                            <div className="posts-card-popular__title card-body">
                                                <small className="text-muted text-uppercase">Asha</small>
                                                <a className="card-title" href="fixed-student-course.html">Course for
                                                    Asha</a>
                                            </div>
                                        </div>
                                    </div>

                                </div> */}


                            </div>


                        </div>
                    </div>


                    {/*<div className="page-section border-bottom-2">
                        <div className="container page__container">
                            <div className="page-separator">
                                <div className="page-separator__text">Modules</div>
                            </div>


                            <div className="row card-group-row">

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/react_40x40@2x.png" width="40"
                                                                     height="40" alt="Angular" className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 1</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/react_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 1</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with React
                                            Native and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/devops_40x40@2x.png"
                                                                     width="40" height="40" alt="Angular"
                                                                     className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 2</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/devops_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 2</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with Dev Ops
                                            and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/redis_40x40@2x.png" width="40"
                                                                     height="40" alt="Angular" className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 3</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/redis_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 3</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with Redis
                                            and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                            </div>


                            <div className="row card-group-row mb-lg-8pt">

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card mb-lg-0"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/mailchimp_40x40@2x.png"
                                                                     width="40" height="40" alt="Angular"
                                                                     className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 4</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/mailchimp_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 4</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with
                                            MailChimp and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card mb-lg-0"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/swift_40x40@2x.png" width="40"
                                                                     height="40" alt="Angular" className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 5</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/swift_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 5</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with Swift
                                            and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                                <div className="col-sm-4 card-group-row__col">

                                    <div
                                        className="card js-overlay card-sm overlay--primary-dodger-blue stack stack--1 card-group-row__card mb-lg-0"
                                        data-toggle="popover" data-trigger="click">

                                        <div className="card-body d-flex flex-column">
                                            <div className="d-flex align-items-center">
                                                <div className="flex">
                                                    <div className="d-flex align-items-center">
                                                        <div className="rounded mr-12pt z-0 o-hidden">
                                                            <div className="overlay">
                                                                <img src="assets/images/paths/wordpress_40x40@2x.png"
                                                                     width="40" height="40" alt="Angular"
                                                                     className="rounded"/>
                                                                <span className="overlay__content overlay__content-transparent">
                                                            <span
                                                                className="overlay__action d-flex flex-column text-center lh-1">
                                                                <small className="h6 small text-white mb-0"
                                                                       style={{fontWeight:500}}>80%</small>
                                                            </span>
                                                        </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex">
                                                            <div className="card-title">Modules 6</div>
                                                            <p className="flex text-black-50 lh-1 mb-0"><small>18
                                                                courses</small></p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <a href="#" data-toggle="tooltip" data-title="Add Favorite"
                                                   data-placement="top" data-boundary="window"
                                                   className="ml-4pt material-icons text-20 card-course__icon-favorite">favorite_border</a>

                                            </div>


                                        </div>
                                    </div>

                                    <div className="popoverContainer d-none">
                                        <div className="media">
                                            <div className="media-left mr-12pt">
                                                <img src="assets/images/paths/wordpress_40x40@2x.png" width="40" height="40"
                                                     alt="Angular" className="rounded"/>
                                            </div>
                                            <div className="media-body">
                                                <div className="card-title">Modules 6</div>
                                                <p className="text-black-50 d-flex lh-1 mb-0 small">18 courses</p>
                                            </div>
                                        </div>

                                        <p className="mt-16pt text-black-70">Learn the fundamentals of working with
                                            WordPress and how to create basic applications.</p>

                                        <div className="my-32pt">
                                            <div className="d-flex align-items-center mb-8pt justify-content-center">
                                                <div className="d-flex align-items-center mr-8pt">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">access_time</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>50 minutes
                                                        left</small></p>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                <span
                                                    className="material-icons icon-16pt text-black-50 mr-4pt">play_circle_outline</span>
                                                    <p className="flex text-black-50 lh-1 mb-0"><small>12 lessons</small>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <a href="#" className="btn btn-primary mr-8pt">Resume</a>
                                                <a href="#" className="btn btn-outline-secondary ml-0">Start over</a>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <small className="text-black-50 mr-8pt">Your rating</small>
                                            <div className="rating mr-8pt">
                                            <span className="rating__item"><span
                                                className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star</span></span>
                                                <span className="rating__item"><span
                                                    className="material-icons text-primary">star_border</span></span>
                                            </div>
                                            <small className="text-black-50">4/5</small>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>*/}




                    {/* <div className="bg-white border-bottom-2 py-16pt ">
                        <div className="text-center" style={{fontSize:20,fontWeight:'bold'}}>How It <span style={{color:'#4c671b'}}>Works?</span></div>
                        <div className="container page__container">
                                <div className="rw">
                                    <div className="grd">
                                        <div className="boxa">
                                            <div className="boxicon">
                                                <Search color='#759f29'/>
                                            </div>
                                            <div className="boxtitle">
                                                Login
                                            </div>
                                            <div className="boxdescr">
                                                It has survived not only centuries also leap into electronic.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grd">
                                        <img src="assets/images/paths/arrow.png"/>
                                    </div>
                                    <div className="grd">
                                        <div className="boxa">
                                            <div className="boxicon">
                                                <Form color='#759f29'/>
                                            </div>
                                            <div className="boxtitle">
                                            Play Quiz
                                            </div>
                                            <div className="boxdescr">
                                                It has survived not only centuries also leap into electronic.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grd">
                                        <img src="assets/images/paths/arrow.png"/>
                                    </div>
                                    <div className="grd">
                                        <div className="boxa">
                                            <div className="boxicon">
                                                <Badge color='#759f29'/>
                                            </div>
                                            <div className="boxtitle">
                                                Get Certificate
                                            </div>
                                            <div className="boxdescr">
                                                It has survived not only centuries also leap into electronic.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </div> */}





                    <div className="page-section border-bottom-2">
                        <div className="container page__container">


                            <div className="page-section">
                                <div className="container page__container">
                                    <div className="text-center">
                                        <h3>Feedback From Medical Officers</h3>

                                    </div>
                                    <div className="position-relative carousel-card col-lg-12 p-0 mx-auto">
                                        <div className="row d-block js-mdk-carousel" id="carousel-feedback">
                                            <a className="carousel-control-next js-mdk-carousel-control mt-n24pt"
                                               href="#carousel-feedback" role="button" data-slide="next">
                                            <span className="carousel-control-icon material-icons"
                                                  aria-hidden="true">keyboard_arrow_right</span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                            <div className="row mdk-carousel__content">

                                                <div className="col-12 col-md-4">

                                                    <div className="card carsd-feedback card-body">

                                                        <div className="text-center">
                                                            <div style={{height:"30px"}}>
                                                                <a href="#" className="avatar avatar-sm">
                                                                    <span className="avatar-title ">Medical Officer 1</span>
                                                                </a>
                                                            </div>
                                                            <div className="">
                                                                <div className="rating mt-4pt">
                                                            <span className="rating__item"><span
                                                                className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                            <p className="text-70 small mb-0">A wonderful course on how to
                                                                start. Eddie beautifully conveys all essentials of a
                                                                becoming a good Angular developer. Very glad to have taken
                                                                this course. Thank you Eddie Bryan.</p>
                                                    </div>


                                                </div>
                                                <div className="col-12 col-md-4">

                                                    <div className="card carsd-feedback card-body">

                                                        <div className="text-center">
                                                            <div style={{height:"30px"}}>
                                                                <a href="#" className="avatar avatar-sm">
                                                                    <span className="avatar-title ">Medical Officer 2</span>
                                                                </a>
                                                            </div>
                                                            <div className="">
                                                                <div className="rating mt-4pt">
                                                            <span className="rating__item"><span
                                                                className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                            <p className="text-70 small mb-0">A wonderful course on how to
                                                                start. Eddie beautifully conveys all essentials of a
                                                                becoming a good Angular developer. Very glad to have taken
                                                                this course. Thank you Eddie Bryan.</p>
                                                    </div>


                                                </div>
                                                <div className="col-12 col-md-4">

                                                    <div className="card carsd-feedback card-body">

                                                        <div className="text-center">
                                                            <div style={{height:"30px"}}>
                                                                <a href="#" className="avatar avatar-sm">
                                                                    <span className="avatar-title ">Medical Officer 3</span>
                                                                </a>
                                                            </div>
                                                            <div className="">
                                                                <div className="rating mt-4pt">
                                                            <span className="rating__item"><span
                                                                className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                    <span className="rating__item"><span
                                                                        className="material-icons">star_border</span></span>
                                                                </div>
                                                            </div>
                                                        </div>



                                                            <p className="text-70 small mb-0">A wonderful course on how to
                                                                start. Eddie beautifully conveys all essentials of a
                                                                becoming a good Angular developer. Very glad to have taken
                                                                this course. Thank you Eddie Bryan.</p>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

                {/*<footer>

                    <div className="js-fix-footer bg-white border-top-2" data-domfactory-upgraded="fix-footer"
                         style={{opacity:1}}>
                        <div className="container page-section py-lg-48pt">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                            <h4 className="text-70">Quick Link</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <a className="nav-link" href="#">Terms & Conditions</a>
                                                <a className="nav-link" href="#">Privacy Policy</a>

                                            </nav>
                                        </div>
                                        <div className="col-6 col-md-4 mb-24pt mb-md-0">
                                            <h4 className="text-70">Courses</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <a className="nav-link" href="#">Program 1</a>
                                                <a className="nav-link" href="#">Program 2</a>
                                                <a className="nav-link" href="#">Program 3</a>
                                                <a className="nav-link" href="#">Program 4</a>
                                            </nav>
                                        </div>
                                        <div className="col-6 col-md-4 mb-32pt mb-md-0">
                                            <h4 className="text-70">Community</h4>
                                            <nav className="nav nav-links nav--flush flex-column">
                                                <a className="nav-link" href="#">Discussions</a>
                                                <a className="nav-link" href="#">Ask Question</a>
                                                <a className="nav-link" href="#">Student Profile</a>
                                                <a className="nav-link" href="#">Instructor Profile</a>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 text-md-right">
                                    <p className="text-70 brand justify-content-md-end">
                                        <img className="brand-icon" src="assets/images/logoblack.png" width="200"
                                             alt="Tutorio"/>
                                    </p>
                                    <p className="text-muted mb-0 mb-lg-5pt">eLearning Platform for Medical Officers, ANMs,
                                        Peer Educators cover health related education programs with certifications.</p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-footer page-section py-lg-32pt">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2 col-sm-4 mb-24pt mb-md-0">
                                    </div>
                                    <div className="col-md-6 col-sm-4 mb-24pt mb-md-0 d-flex align-items-center">
                                        <a href="" className="btn btn-outline-white">English <span
                                            className="icon--right material-icons">arrow_drop_down</span></a>
                                    </div>
                                    <div className="col-md-4  text-md-right">

                                        <p className="text-black mb-0">Copyright 2023 © All rights reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <Footer/>


            </div>


            <div className="mdk-drawer js-mdk-drawer" id="default-drawer">
                <div className="mdk-drawer__content">
                    <div className="sidebar sidebar-dark-dodger-blue sidebar-left" data-perfect-scrollbar>


                        <div className="d-flex align-items-center navbar-height">
                            <form action="fixed-index.html" className="search-form search-form--black mx-16pt pr-0 pl-16pt">
                                <input type="text" className="form-control pl-0" placeholder="Search"/>
                                <button className="btn" type="submit"><i className="material-icons">search</i></button>
                            </form>
                        </div>


                        <a href="index.html" className="">
                            <img src="assets/images/logo/logo.png" className="img-fluid" alt="logo"/>
                        </a>
                        <div className="sidebar-heading">Student</div>
                        <ul className="sidebar-menu">


                            <li className="sidebar-menu-item active">
                                <a className="sidebar-menu-button" href="fixed-index.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">home</span>
                                    <span className="sidebar-menu-text">Home</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-courses.html">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">local_library</span>
                                    <span className="sidebar-menu-text">Browse Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-dashboard.html">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                                    <span className="sidebar-menu-text">Student Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-my-courses.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">search</span>
                                    <span className="sidebar-menu-text">My Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-course.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">face</span>
                                    <span className="sidebar-menu-text">Course Preview</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-lesson.html">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">panorama_fish_eye</span>
                                    <span className="sidebar-menu-text">Lesson Preview</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-take-quiz.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">dvr</span>
                                    <span className="sidebar-menu-text">Take Quiz</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-quiz-results.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">poll</span>
                                    <span className="sidebar-menu-text">My Quizzes</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-student-quiz-result-details.html">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">live_help</span>
                                    <span className="sidebar-menu-text">Quiz Result</span>
                                </a>
                            </li>

                        </ul>
                        <div className="sidebar-heading">Instructor</div>
                        <ul className="sidebar-menu">


                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-dashboard.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">school</span>
                                    <span className="sidebar-menu-text">Instructor Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-courses.html">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">import_contacts</span>
                                    <span className="sidebar-menu-text">Manage Courses</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="fixed-instructor-quizzes.html">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">help</span>
                                    <span className="sidebar-menu-text">Manage Quizzes</span>
                                </a>
                            </li>
                        </ul>


                    </div>
                </div>

            </div>
        </div>
    );
};

export default Home;