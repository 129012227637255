import React, {useState} from 'react';
import {Link} from "react-router-dom";
import API from '../Queries/API'
import HeaderOut from "../Components/HeaderOut";
import Footer from "../Components/Footer";
import {Checker} from "../Queries/Sessions";
import alertify from "alertifyjs";


const LoginExpert = () => {
    const [loading, setL] = useState(false)
    const [username, setU] = useState('');
    const [password, setP] = useState('')
    const HandleLogin = ()=>{
        if(!username || !password){
            alert("Email and Password is mandatory");
            return;
        }
        setL(true)
        let data = {
            username,
            password,
            perform:'loginexpert'
        }
        fetch(API.Experts,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify(data)
        }).then((response)=> response.json())
            .then((rsp)=>{
                console.log({rsp})
                if(rsp.status){
                    localStorage.setItem('usertoken',rsp.token)
                    localStorage.setItem('userData',JSON.stringify(rsp))
                    window.location.href="./expert-questions"
                    return;
                }
                alertify.alert('E-Learning',rsp.msg);



            })
            .finally(()=>{
                setL(false)
            })
    }
    return (
        <div className="layout-sticky-subnav layout-default ">

            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout" style={{paddingTop: 80}}>

                {/*<HeaderOut/>*/}

                <div className="mdk-header-layout__content page-content" style={{paddingBottom: '80px'}}>

                    <div className="pt-132pt pt-sm-64pt pb-32pt">
                        <div className="container page__container">
                            <form action="#" className="col-md-5 p-0 mx-auto">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="email">Email:</label>
                                    <input onChange={(v)=> {
                                        setU(v.target.value)
                                    }} value={username} id="email" type="text" className="form-control"
                                           placeholder="Your email address ..."/>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="password">Password:</label>
                                    <input id="password" type="password" className="form-control"
                                           onChange={(v)=> {
                                               setP(v.target.value)
                                           }}
                                           placeholder="Your first and last name ..."/>
                                    <p className="text-right"><Link to="#./reset-password" className="small">Forgot
                                        your password?</Link></p>
                                </div>
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary" onClick={()=>HandleLogin()}>
                                        {loading && <i className="fa fa-spinner fa-spin"></i>}
                                        Login</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <Footer/>

            </div>



        </div>
    );
};

export default LoginExpert;