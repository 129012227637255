import logo from './logo.svg';
import './App.css';
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import ResetPassword from "./Pages/ResetPassword";
import EditProfile from "./Pages/EditProfile";
import Dashboard from "./Pages/Dashboard";
import Courses from "./Pages/Courses";
import TakeCourse from "./Pages/TakeCourse";
import QuizScore from "./Pages/QuizScore";
import RateReview from "./Pages/RateReview";
import TestResult from "./Pages/TestResult";
import AskExpert from "./Pages/AskExpert";
import {BrowserRouter, Route, Router, Routes, Switch} from "react-router-dom";
import LoginExpert from "./Pages/LoginExpert";
import ExpertQuestions from "./Pages/ExpertQuestions";
import FAQPage from "./Pages/FAQPage";
import MeetingsPage from "./Pages/Meetings";



function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" index element={<Home />} />
                <Route exact path="/login" index element={<Login />} />
                <Route exact path="/reset-password" index element={<ResetPassword />} />
                <Route exact path="/edit-profile" index element={<EditProfile />} />
                <Route exact path="/dashboard" index element={<Dashboard />} />
                <Route exact path="/courses" index element={<Courses />} />
                <Route exact path="/take-course" index element={<TakeCourse />} />
                <Route exact path="/quiz-score" index element={<QuizScore />} />
                <Route exact path="/rate-review" index element={<RateReview />} />
                <Route exact path="/pretest-result" index element={<TestResult mode="preTest"/>} />
                <Route exact path="/posttest-result" index element={<TestResult mode="postTest" />} />
                <Route exact path="/ask-expert" index element={<AskExpert/>} />
                <Route exact path="/login-expert" index element={<LoginExpert/>} />
                <Route exact path="/expert-questions" index element={<ExpertQuestions/>} />
                <Route exact path="/faq" index element={<ExpertQuestions/>} />
                <Route exact path="/faqs" index element={<FAQPage/>} />
                <Route exact path="/meetings" index element={<MeetingsPage/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
