import React from 'react';
import API from "../Queries/API";

const Instruction = () => {
    return (
        <div>
            <h4>User can access dashboard / Performance Monitoring </h4>
            <ul className="list-group-item px-3 py-12pt forced-padding33 card-hr" style={{fontSize:16,fontWeight:500}}>
                <li style={{paddingBottom:6,marginTop:8}}>The users have to complete the full course in a duration of three months.</li>
                <li style={{paddingBottom:6,marginTop:8}}>After the course completion, module access will be available to user for life.</li>
                <li style={{paddingBottom:6,marginTop:8}}>User can view his/her course progress status on the dashboard</li>
                <li style={{paddingBottom:6,marginTop:8}}>The users will be able to access comparative analysis of their pre-post-test comparison. It is to be noted that the users have to achieve {API.MIN_SCORE}% score in the post-test to activate the next module.</li>
                <li style={{paddingBottom:6,marginTop:8}}>The users will be able to access/download their Certificates after completion of the full course and attempting the ‘Quick Check and evaluation’.</li>
                <li style={{paddingBottom:6,marginTop:8}}>It is important for the user to complete the evaluation for generating the certificate.</li>
            </ul>


            <h4>Pre-test, Post test and evaluation format</h4>
            <ul className="list-group-item px-3 py-12pt forced-padding33 card-hr" style={{fontSize:16,fontWeight:500}}>
                <li style={{paddingBottom:6,marginTop:8}}>Before starting any module, user will have to take pre-test in the form of Multiple-Choice Questions consisting of 10 questions and after completion of module user have to take post-test which will be same as Pre-test.</li>
                <li style={{paddingBottom:6,marginTop:8}}>Pre-test will be single time however; post-test can be attempted multiple times.</li>
                <li style={{paddingBottom:6,marginTop:8}}>Attempt all the interactive elements example Quiz, Poll, etc. as these are mandatory. Score of interactive elements will not be part of final score. </li>
                {/*<li style={{paddingBottom:6,marginTop:8}}>Every month, the users will receive the prompt to complete the course. The total duration for the course completion is three months</li>*/}
                <li style={{paddingBottom:6,marginTop:8}}>The user can ask a question and their queries will be responded by the State level master trainers in RKSK. The responses will be shared with the users weekly.</li>
            </ul>

            <h4>How do I Ask question to the experts?</h4>
            <ul className="list-group-item px-3 py-12pt forced-padding33 card-hr" style={{fontSize:16,fontWeight:500}}>
                <li style={{paddingBottom:6,marginTop:8}}>There are five thematic experts listed here and you can ask your queries to any of the experts.</li>
                <li style={{paddingBottom:6,marginTop:8}}>Click the button ‘Ask your question’ and a window will open. It will ask you to select the theme and type your question.</li>
                <li style={{paddingBottom:6,marginTop:8}}>Your question will directly reach the mailbox of the respective thematic expert.</li>
                <li style={{paddingBottom:6,marginTop:8}}>The thematic expert will revert to your query within a week.</li>
                <li style={{paddingBottom:6,marginTop:8}}>It is advised not to send clinical queries to the experts. You may ask questions related to the programme, counselling, technical information and adolescent behaviour.</li>
            </ul>

            <h4>Meeting Scheduler</h4>
            <ul className="list-group-item px-3 py-12pt forced-padding33 card-hr" style={{fontSize:16,fontWeight:500}}>
                <li style={{paddingBottom:6,marginTop:8}}>Meeting will be scheduled by the Admin.</li>
                <li style={{paddingBottom:6,marginTop:8}}>All upcoming meetings can be viewed on the calendar.</li>
                <li style={{paddingBottom:6,marginTop:8}}>The most recent scheduled meeting will be visible under meeting and course status.</li>
                <li style={{paddingBottom:6,marginTop:8}}>The link will be provided at the scheduled time on calendar and on clicking on the link, the meeting window will open.</li>
                <li style={{paddingBottom:6,marginTop:8}}>Multiple meetings can be scheduled for a user who can join accordingly.</li>
            </ul>
        </div>
    );
};

export default Instruction;