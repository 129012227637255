import React, {useEffect, useState} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import Overlay from "../Components/Overlay";
import {Checker} from "../Queries/Sessions";

const RateReview = () => {
    const [showMenu, setShowMenu] = useState(false)
    useEffect(()=>{
        // console.log("ss")
        Checker()
    },[])

    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default ">

        <div className="preloader">
            <div className="sk-double-bounce">
                <div className="sk-child sk-double-bounce1"></div>
                <div className="sk-child sk-double-bounce2"></div>
            </div>
        </div>

        <div className="mdk-header-layout js-mdk-header-layout">


            <Header showMenu={setShowMenu}/>


            <div className="mdk-header-layout__content page-content ">

                <div className="pt-32pt">
                    <div
                        className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">

                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Rate & Reviews</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="fixed-instructor-dashboard.html">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Rate & Reviews
                                    </li>
                                </ol>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="container page__container page-section">

                    <div className="row mb-32pt">

                        <div className="col-lg-12 d-flex align-items-center">
                            <div className="flex" style={{maxWidth: '100%'}}>

                                <form action="./rate-review" className="col-sm-5 p-0">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="password">Enter Your Comments </label>
                                        <textarea id="w3review" className="form-control" name="w3review" rows="4"
                                                  cols="50"></textarea>
                                    </div>
                                    <div className="form-group">
                                          <div className="rate">
                                            <input type="radio" id="star5" name="rate" value="5"/>
                                            <label htmlFor="star5" title="text">5 stars</label>
                                            <input type="radio" id="star4" name="rate" value="4"/>
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star3" name="rate" value="3"/>
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star2" name="rate" value="2"/>
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star1" name="rate" value="1"/>
                                            <label htmlFor="star1" title="text">1 star</label>
                                        </div>
                                    </div>
                                    <br/><br/>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">Add Rate & Reviews
                                            </button>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
            <Footer/>


        </div>


        <script src="assets/vendor/jquery.min.js"></script>

        <script src="assets/vendor/popper.min.js"></script>
        <script src="assets/vendor/bootstrap.min.js"></script>

        <script src="assets/vendor/perfect-scrollbar.min.js"></script>

        <script src="assets/vendor/dom-factory.js"></script>

        <script src="assets/vendor/material-design-kit.js"></script>

        <script src="assets/vendor/fix-footer.js"></script>

        <script src="assets/js/app.js"></script>

        </div>
        </div>

);
};

export default RateReview;