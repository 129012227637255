export const lessonHelper = {
    mapLockId: (xLessons)=>{

        // console.log({xLessons})
        let m = xLessons.map((oneLesson,L)=>{
            // subjects.sort( (a,b) => a.score - b.score );
            let sections = oneLesson?.lessons;
            sections.sort((a,b)=>a.lockId - b.lockId);
            let LowestLockId = sections[0]?.lockId;

            sections.sort((a,b)=>b.lockId - a.lockId);
            let HighestSection = sections[0];
            let HighestLockId = HighestSection?.lockId;
            if(HighestSection?.activities){
                let Activities = HighestSection.activities;
                Activities.sort((a,b)=>b.lockId - a.lockId);
                HighestLockId = Activities[0]?.lockId
            }
            return {id:oneLesson?.id,maxLock:HighestLockId,minLock:LowestLockId}
        })
        m.sort((a, b) => (a.maxLock > b.maxLock) ? 1: -1);
        return m;

    },
    highestLockId: (xLessons)=>{
        const mapLock = lessonHelper.mapLockId(xLessons)
        mapLock.sort((a, b) => (a.maxLock < b.maxLock) ? 1: -1);
        // console.log({mapLock})
        return mapLock.length > 0 ? mapLock[0].maxLock : 0;
    },
}