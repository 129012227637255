import React from 'react';
import Sidebar from "./Sidebar";

const Overlay = (Prop) => {
    return (
        <div style={styles.Bg} onClick={()=>Prop.showMenu(false)}>
            <div style={styles.Menu}>
                <Sidebar/>
            </div>
        </div>
    );
};

const styles = {
    Bg:{
        backgroundColor:"rgba(0,0,0,0.45)",
        height:'100vh',
        width:'100%',
        position:'fixed',
        top:0,
        zIndex:2,
    },
    Menu:{
        maxWidth:256,
        height: '100vh'
    }
}
export default Overlay;