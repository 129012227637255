import React, {useEffect, useState} from 'react';
import Overlay from "../Components/Overlay";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import $ from "jquery";
import alertify from "alertifyjs";
import API from "../Queries/API";
import {Checker} from "../Queries/Sessions";


const AskExpert = () => {
    const [showMenu, setShowMenu] = useState(false)
    const [isLoading, setIL] = useState(false)
    const handleSubmit = ()=>{
        if($("#form10").val() == ""){
            alertify.alert("E-Learning","Your question cannot be empty");
            return;
        }
        setIL(true);





        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'askexpert',
            "user":JSON.parse(localStorage.userData).uid,
            "theme":$("#theme").val(),
            "question":$("#form10").val(),
            "expertId":ActiveExpert.id
        });
        console.log({raw})
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Experts, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({experts:result})
                // setExperts(result)


                // alertify.alert("E-Learning",`Your Question has been submitted, You will be notified as soon as ${ActiveExpert.name} answers your question`);
                alertify.alert("E-Learning",result.message.replace("{expert_name}",ActiveExpert.name));
                $("#form10").val("");


            })
            .catch(error => console.log('error', error))
            .finally(()=>{
                setIL(false)
            });


    }
    const [Experts, setExperts] = useState([])
    const [ActiveExpert,setAExpert] = useState({})
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'experts',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Experts, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({experts:result})
                setExperts(result)
            })
            .catch(error => console.log('error', error));
    },[])
    useEffect(()=>{
        // console.log("ss")
        Checker()
    },[])




    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default">
            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>

            <div className="mdk-header-layout js-mdk-header-layout" style={{backgroundColor: '#ffffff'}}>

                <Header showMenu={setShowMenu}/>



                <div className="mdk-header-layout__content page-content ">


                    <div className="container page__container">

                        <section id="" data-text-list="[]" data-scroll-target="#"
                                 className="first-fold align-center image-wrap     ">
                            <div className="banner-cover">
                                <img src="assets\images\Ask.jpg" alt=""
                                     style={{marginLeft: -206,height: 568, width: 1500}}/>


                            </div>

                        </section>

                        <section id="expertsection" className="section-bucket section-text-media pb-xl  "
                                 data-scroll-target="expertsection">
                            {/* <div className="l-page container align-center mb-lg">
                                <h1 className="mt-0 text-center"><br/>Our Experts</h1>
                            </div> */}


                            {
                                Experts.map((oneExpert,I)=>{
                                    return (<div className="feature-screenshot-left" key={I}>
                                        <div className="l-page container">
                                            <div className="row ">
                                                <div
                                                    className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                                    <div className="lazy-image-wrapper ">
                                                        <img className="lazy-image " data-src={oneExpert.photo}
                                                             data-srcset={oneExpert.photo}
                                                             srcSet={oneExpert.photo}
                                                             alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                             width="90%"/>

                                                    </div>
                                                </div>
                                                <div id="feature-content-82494143472727"
                                                     className=" feature-screenshot-left__content col-md-6">
                                                    <h5><br/><strong>{oneExpert.name}</strong></h5>
                                                    {/* <h6><strong>Email:</strong> {oneExpert.email}</h6> */}
                                                    <h6><strong>Theme: {oneExpert.theme}</strong></h6>
                                                    <p style={{textAlign:"justify"}}>
                                                        {oneExpert.descr}
                                                    </p>
                                                    <div className="pt-sm mobile-align-center">
                                                        <button
                                                            data-toggle="modal" data-target="#myModal"
                                                            title="" className="btn btn-primary " href=""
                                                            rel="" role="button" tabIndex="0"
                                                            onClick={()=>{
                                                                setAExpert(oneExpert)
                                                            }}
                                                        >Ask your Question
                                                        </button>

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>)
                                })
                            }
{/*
                            <div className="feature-screenshot-left ">
                                <div className="l-page container">
                                    <div className="row ">
                                        <div
                                            className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                            <div className="lazy-image-wrapper ">
                                                <img className="lazy-image " data-src="assets\images\drsheily.jpg"
                                                     data-srcset="assets\images\drsheily.jpg"
                                                     srcSet="assets\images\drsheily.jpg"
                                                     alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                     width="90%"/>

                                            </div>
                                        </div>
                                        <div id="feature-content-82494143472727"
                                             className=" feature-screenshot-left__content col-md-6">
                                            <h5><br/><strong> Dr. Sheily Sharma</strong></h5>
                                            <h6><strong>Email:</strong> sharmasheilly@gmail.com</h6>
                                            <h6><strong>Theme: Sexual and Reproductive Health</strong></h6>
                                            <p><b>She is the State trainer for Adolescent health programme, RBSK,
                                                immunisation
                                                and AEFI, MAA and NSSK.</b>Sheily Sharma, is a Paediatrician working
                                                with Himachal Health
                                                Services since 1999. Having graduated from NRS Medical college
                                                Kolkata and Post-Graduation from IGMC Shimla, Dr Sheily
                                                Sharma has 24 years of experience with 19 years dedicated to
                                                working in the field of Paediatrics. Actively associated in
                                                trainings in varied aspects of child health, she is a national
                                                trainer in Infant and Young Children Feeding Practices. </p>
                                            <p>Being a member of BPNI, new-born feeding and fight against
                                                Malnutrition and Anaemia are her key area of interest. She is
                                                also the member of core group for Poshan Abhiyan, district
                                                Kangra. Being an empanelled national assessor for NQAS with
                                                NHSRC New Delhi, she has worked extensively for improving
                                                standards in delivering quality health care. She is presently
                                                working as Medical Officer (Paediatrics) at Civil Hospital
                                                Fatehpur, district-Kangra. </p>
                                            <div className="pt-sm mobile-align-center">
                                                <button
                                                    data-toggle="modal" data-target="#myModal"
                                                    title="" className="btn btn-primary " href=""
                                                    rel="" role="button" tabIndex="0"
                                                >Ask your Question
                                                </button>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>



                            <div className="feature-screenshot-left ">
                                <div className="l-page container">
                                    <div className="row ">
                                        <div
                                            className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                            <div className="lazy-image-wrapper ">
                                                <img className="lazy-image " data-src="assets\images\drpawnesh.jpg"
                                                     data-srcset="assets\images\drpawnesh.jpg"
                                                     srcSet="assets\images\drpawnesh.jpg"
                                                     alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                     width="90%"/>

                                            </div>
                                        </div>
                                        <div id="feature-content-82494143472727"
                                             className=" feature-screenshot-left__content col-md-6">
                                            <h5><br/><strong> Dr. Pawnesh Kumar</strong></h5>
                                            <h6><strong>Email:</strong> pawneshkmahant@gmail.com
                                            </h6>
                                            <h6><strong>Theme: Nutrition, Sexual and Reproductive Health and Mental
                                                Health</strong></h6>
                                            <p> Dr Pawnesh Kumar is a medical doctor and public health expert.
                                                After completion of his MBBS from IGMC Shimla, he served as
                                                medical officer under government of Himachal Pradesh. After
                                                more than a decade as a medical officer, he pursued Masters in
                                                public health from PGIMER Chandigarh. Now, working he is
                                                working as a district program officer in district Mandi. </p>
                                            <p>Adolescent wellbeing is one of area he is very passionate about.
                                                His MPH thesis was also on “bullying in schools “. He has also
                                                authored a life skills book for teenagers called: Hit It! which is an
                                                attempt to provide strategies to solve problems that teenagers
                                                go through on day-to-day.</p>
                                            <div className="pt-sm mobile-align-center">
                                                <button
                                                    data-toggle="modal" data-target="#myModal"
                                                    title="" className="btn btn-primary " href=""
                                                    rel="" role="button" tabIndex="0"
                                                >Ask your Question
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="feature-screenshot-left ">
                                <div className="l-page container">
                                    <div className="row ">
                                        <div
                                            className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                            <div className="lazy-image-wrapper " >
                                                <img className="lazy-image " data-src="assets\images\drsatya.jpg"
                                                     data-srcset="assets\images\drsatya.jpg"
                                                     srcSet="assets\images\drsatya.jpg"
                                                     alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                     width="90%"/>

                                            </div>
                                        </div>
                                        <div id="feature-content-82494143472727"
                                             className=" feature-screenshot-left__content col-md-6">
                                            <h5><br/><strong> Dr. Satyavrat Vaidya</strong></h5>
                                            <h6><strong>Email:</strong> satyavaidya@gmail.com </h6>
                                            <h6><strong>Theme: Substance Misuse</strong></h6>
                                            <p><b>He has been working with adolescents and is master trainer
                                                for adolescent health in the state.</b>He is the District program
                                                officer cum Nodal Head of Addiction treatment facility, Kullu.
                                                He is trained in addiction medicine, community psychiatry and
                                                psycho- sexual medicine from NIMHANS. </p>
                                            <p>He is working with the department of Health and Family
                                                Welfare for the last 26 years and has worked extensively in the
                                                field of maternal and child health. He imparts regular trainings
                                                on substance abuse management to doctors and paramedical
                                                staff in the state. He is a member of district task force of Nasha
                                                Mukt Bharat Abhiyan and is a key core member for policy
                                                making and implementation. Currently he is promoting tobacco
                                                cessation program and is a case facilitator for the same
                                                program in NIMHANS. </p>
                                            <div className="pt-sm mobile-align-center">
                                                <button
                                                    data-toggle="modal" data-target="#myModal"
                                                    title="" className="btn btn-primary " href=""
                                                    rel="" role="button" tabIndex="0"
                                                >Ask your Question
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                            <div className="feature-screenshot-left ">
                                <div className="l-page container">
                                    <div className="row ">
                                        <div
                                            className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                            <div className="lazy-image-wrapper " >
                                                <img className="lazy-image " data-src="assets\images\drprithi.jpg"
                                                     data-srcset="assets\images\drprithi.jpg"
                                                     srcSet="assets\images\drprithi.jpg"
                                                     alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                     width="90%"/>

                                            </div>
                                        </div>
                                        <div id="feature-content-82494143472727"
                                             className=" feature-screenshot-left__content col-md-6">
                                            <h5><br/><strong> Dr. Prithi Choudhary</strong></h5>
                                            <h6><strong>Email:</strong> prithimast@gmail.com
                                            </h6>
                                            <h6><strong>Theme: Non Communicable Diseases, Promotion of safe use of
                                                internet, gadgets and social media</strong>
                                            </h6>
                                            <p>Dr Prithi Chaudhary is posted as Senior Medical Officer at Civil
                                                Hospital Bhoranj Dist. Hamirpur. He has joined health department
                                                in 2014 and has an experience of more than 9 years in curative and
                                                public health. He is certified master trainer for RKSK and
                                                Adolescent Friendly Health Clinic from Ministry of Health and
                                                Family Welfare GOI and runs OPD for adolescents very efficiently.
                                            </p>
                                            <div className="pt-sm mobile-align-center">
                                                <button
                                                    data-toggle="modal" data-target="#myModal"
                                                    title="" className="btn btn-primary " href=""
                                                    rel="" role="button" tabIndex="0"
                                                >Ask your Question
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>


                            <div className="feature-screenshot-left ">
                                <div className="l-page container">
                                    <div className="row ">
                                        <div
                                            className="feature-screenshot-left__media feature-screenshot__media-container col-md-6">
                                            <div className="lazy-image-wrapper " >
                                                <img className="lazy-image " data-src="assets\images\dranvesha.jpg"
                                                     data-srcset="assets\images\dranvesha.jpg"
                                                     srcSet="assets\images\dranvesha.jpg"
                                                     alt="freshworks2020 asktheexpert sprekers v3 simonejomoore"
                                                     width="90%"/>

                                            </div>
                                        </div>
                                        <div id="feature-content-82494143472727"
                                             className=" feature-screenshot-left__content col-md-6">
                                            <h5><br/><strong> Dr. Anvesha Negi</strong></h5>
                                            <h6><strong>Email:</strong> anveshanegi17@gmail.com
                                            </h6>
                                            <h6><strong>Theme: Mental Health</strong>
                                            </h6>

                                            <p> Dr Anvesha Negi is a Public Health Specialist working in Himachal
                                                Pradesh State Medical Services as District Program Officer (DPO).
                                                A State level awardee, Dr Anvesha as a DPO is looking after
                                                programs related to - Child Health (Routine immunisation, RBSK),
                                                Adolescent Health (RKSK), RCH &amp; HMIS portal, Ayushman Bharat
                                                Health and Wellness Centres, in the remote tribal district Kinnaur
                                                of Himachal Pradesh. </p>
                                            <p>She is MBBS from Lady Hardinge Medical College New Delhi, MD
                                                Community Medicine from PGIMS Rohtak and Diploma in
                                                Community Mental Health from NIMHANS Bangalore.
                                            </p>
                                            <p>She is a State ToT for Routine Immunization/ Cold chain and
                                                RKSK. She is district ToT for NSSK (Navjaat Shishu Suraksha
                                                Karyakram). She is a life time member of National Institute of
                                                Nutrition, Hyderabad. She is also a member of District Kinnaur’s
                                                Local Complaint Committee against Sexual Harassment of
                                                women at Workplace.</p>
                                            <div className="pt-sm mobile-align-center">
                                                <button
                                                    data-toggle="modal" data-target="#myModal"
                                                    title="" className="btn btn-primary " href=""
                                                    rel="" role="button" tabIndex="0"
                                                >Ask your Question
                                                </button>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>*/}


                        </section>


                    </div>
                    
                    <Footer/>

                </div>


            </div>
        </div>


            <div className="container">
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Ask the Question</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>

                            </div>
                            <div className="modal-body">


                                <div className="col-lg-10 p-0 mx-auto">
                                    <div className="row">
                                        <div className="col-md-12 mb-24pt mb-md-0">
                                            <form action="#">
                                                <div className="form-group">
                                                    <div>
                                                        <label htmlFor="Expert Name" className="form-label">{ActiveExpert?.name}</label>

                                                    </div>
                                                    
                                                    
                                                    
                                                    <label className="form-label" htmlFor="custom-select">Choose
                                                        Topic</label>
                                                    <select id="theme" className="form-control custom-select" disabled={isLoading}>
                                                        <option selected>Choose Topic</option>
                                                        <option xvalue="1">Rashtriya Kishor Swasthya Karyakram</option>
                                                        <option xvalue="2">Adolescent Friendly Approach: Qualities and Skills</option>
                                                        <option xvalue="3">Nutrition and Anaemia in Adolescents</option>
                                                        <option xvalue="4">Promoting Healthy Lifestyles</option>
                                                        <option xvalue="5">Adolescent Mental Health</option>
                                                        <option xvalue="6">Sexual and Reproductive Health during Adolescence</option>
                                                        <option xvalue="7">Prevention and Management of Substance Misuse</option>
                                                        <option xvalue="8">Prevention and Redressal of Injuries and Violence amongst Adolescents</option>
                                                        <option xvalue="9">Promotion of Safe Use of Internet, Gadgets and Social Media</option>
                                                        <option xvalue="10">COVID-19 Pandemic</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">

                                                    <label className="form-label" htmlFor="custom-select">Ask Your
                                                        Question</label>
                                                    <textarea id="form10" className="md-textarea form-control"
                                                              rows="3" disabled={isLoading}></textarea>

                                                </div>

                                                <button onClick={()=>handleSubmit()} disabled={isLoading} className="btn btn-primary"><i className={isLoading ? "fa-spinner fa-spin" : ""}></i>{isLoading ? 'Submitting...' : 'Submit'}</button>
                                            </form>
                                        </div>

                                    </div>
                                </div>






                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default AskExpert;