import React from 'react';

const Sidebar = () => {
    return (
                    <div className="sidebar sidebar-dark-dodger-blue sidebar-left" >


                        {/*<a href="./dashboard" className="">
                            <img src="assets/images/logo/logo.png" className="img-fluid" alt="logo"/>
                        </a>*/}


                        <div className="sidebar-heading">Student</div>
                        <ul className="sidebar-menu">


                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="./dashboard">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">account_box</span>
                                    <span className="sidebar-menu-text">Student Dashboard</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="./courses">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">search</span>
                                    <span className="sidebar-menu-text">My Courses</span>
                                </a>
                            </li>

                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="./quiz-score">
                                <span
                                    className="material-icons sidebar-menu-icon sidebar-menu-icon--left">live_help</span>
                                    <span className="sidebar-menu-text">Quiz Result</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="./rate-review">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">face</span>
                                    <span className="sidebar-menu-text">Rate & Reviews</span>
                                </a>
                            </li>
                            <li className="sidebar-menu-item">
                                <a className="sidebar-menu-button" href="./ask-expert">
                                    <span className="material-icons sidebar-menu-icon sidebar-menu-icon--left">dvr</span>
                                    <span className="sidebar-menu-text">Ask a Mentor</span>
                                </a>
                            </li>

                        </ul>


                    </div>
    );
};

export default Sidebar;