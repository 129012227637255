import React from 'react';
import tippy from 'tippy.js';

function EventComponent({ event }) {
    const description = event.extendedProps.description;

    React.useEffect(() => {
        tippy('.event-tooltip', {
            content: description,
            arrow: true,
        });
    }, [description]);

    return (
        <div className="event-tooltip">
            {event.title}
        </div>
    );
}

export default EventComponent;
