import React, {useEffect, useState} from 'react';
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/Header";
import Overlay from "../Components/Overlay";
import CourseDetails from "../DataFiles/Courses";
import useCourses from "../DataFiles/Courses";
import {Checker} from "../Queries/Sessions";
const Courses = () => {
    const [showMenu, setShowMenu] = useState(false)
    const {data:Courses, isLoadingCourse, courseError} = useCourses()

    useEffect(()=>{
        // console.log("ss")
        Checker()
    },[])

    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
            <div className="layout-sticky-subnav layout-default ">

                <div className="preloader">
                    <div className="sk-double-bounce">
                        <div className="sk-child sk-double-bounce1"></div>
                        <div className="sk-child sk-double-bounce2"></div>
                    </div>
                </div>

                <div className="mdk-header-layout js-mdk-header-layout">

                    <Header showMenu={setShowMenu}/>
                    <div className="mdk-header-layout__content page-content ">


                        <div className="pt-32pt">
                            <div
                                className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                                <div className="flex d-flex flex-column flex-sm-row align-items-center mb-24pt mb-md-0">

                                    <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                        <h2 className="mb-0">Courses</h2>

                                        <ol className="breadcrumb p-0 m-0">
                                            <li className="breadcrumb-item"><a href="./dashboard">Home</a></li>

                                            <li className="breadcrumb-item active">

                                                Courses

                                            </li>

                                        </ol>

                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="container page__container">

                            <div className="row card-group-row mt-5">

                                {
                                    Courses?.map((oneCourse)=>{
                                        return (
                                            <div className="col-md-6 col-lg-4 card-group-row__col">

                                                <div className="card card--elevated posts-card-popular overlay card-group-row__card">
                                                    <img src="assets/images/paths/sketch_430x168.png" alt="" className="card-img"/>
                                                    <div className="fullbleed bg-primary" style={{opacity: .5}}></div>
                                                    <div className="posts-card-popular__content">

                                                        <div className="posts-card-popular__title card-body">
                                                            <a className="card-title" href="./take-course">{oneCourse?.Title}</a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                }
                            </div>


                        </div>


                    </div>
                    <Footer/>

                </div>


                <script src="assets/vendor/jquery.min.js"></script>

                <script src="assets/vendor/popper.min.js"></script>
                <script src="assets/vendor/bootstrap.min.js"></script>

                <script src="assets/vendor/perfect-scrollbar.min.js"></script>

                <script src="assets/vendor/dom-factory.js"></script>

                <script src="assets/vendor/material-design-kit.js"></script>

                <script src="assets/vendor/fix-footer.js"></script>

                <script src="assets/js/app.js"></script>


            </div>

        </div>

);
};

export default Courses;