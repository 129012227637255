/*
export default [
    {
        id: 1,
        title: "Prevention and Management of Substance Misuse",
        status:1,
        lessons:[
            {
                id: 1,
                Title: "Section 1: Introduction and Substance misuse during adolescence",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/823991477?h=a31050dd14",
                lockId:1,
                activities:[
                    {
                        id:1,
                        Title: "Link to suggested answers (Story of Ronil)",
                        Type: "Page",
                        Story:"ronil",
                        URL:"",
                        lockId:2,
                    },
                    {
                        id:2,
                        Title: "Link to suggested answers (Story of Mubin)",
                        Type: "Page",
                        Story:"mubin",
                        URL:"",
                        lockId:3,

                    },
                ],
            },
            {
                id: 2,
                Title: "Section 2 (part 1): Understanding Substance Misuse",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/823994090?h=0164e44a12",
                lockId:4,
                activities:[
                    {
                        id: 1,
                        Title: "Activity 1: Physical Presentations of Substance Misuse among Adolescents",
                        URL: "https://rkskpeereducator.in/elearning/demo/Activity1/",
                        Type: "Activity",
                        lockId:5,
                    },
                    {
                        id: 2,
                        Title: "Activity 2: Emotional Presentations of Substance Misuse among Adolescents",
                        URL: "https://rkskpeereducator.in/elearning/demo/Activity2/",
                        Type: "Activity",
                        lockId: 6,
                    },
                    {
                        id: 3,
                        Title: "Activity 3: Social Presentations of Substance Misuse among Adolescents",
                        URL: "https://rkskpeereducator.in/elearning/demo/Activity3/",
                        Type: "Activity",
                        lockId: 7,
                    },
                    {
                        id: 5,
                        Title: "Activity 4: Presentation of Substance Misuse",
                        URL: "https://rkskmamta.in/activities/dragdrop/",
                        Type: "Activity",
                        lockId: 8,
                    }

                ]
            },
            {
                id: 3,
                Title: "Section 2 (part 2): Understanding Substance Misuse",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/823996011?h=56bb92ab32",
                lockId: 9,
                activities:[
                    {
                        id: 7,
                        Title: "Activity 5: Matching Correct Type",
                        URL: "https://rkskmamta.in/activities/matching/",
                        Type: "Activity",
                        lockId: 10,
                    },
                ]
            },
            {
                id: 4,
                Title: "Section 3: Factors driving substance misuse ",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/823997262",
                lockId: 11,
            },
            {
                id: 5,
                Title: "Section 4: Laws related to substance misuse",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/823997985",
                lockId: 12,
            },
            {
                id: 6,
                Title: "Section 5: Responding to substance misuse among adolescents (Role of Medical Officers)",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/824000541",
                lockId: 13,
                activities:[
                    {
                        id: 6,
                        Title: "Activity 6: Fill in the Blank",
                        URL: "https://rkskmamta.in/activities/filltheblank/",
                        Type: "Activity",
                        lockId: 14,
                    },
                    {
                        id: 8,
                        Title: "Activity 7: True of False",
                        URL: "https://rkskmamta.in/activities/truefalse/",
                        Type: "Activity",
                        lockId: 15,
                    },
                ]
            },
            {
                id: 7,
                Title: "Section 6: Role of Medical Officers (Case Study of Santosh)",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/824002066",
                lockId: 16,
                activities:[
                    {
                        id:1,
                        Title: "Link to suggested answers (Story of Santosh)",
                        Type: "Page",
                        Story:"santosh",
                        URL:"",
                        lockId:17,

                    },

                ],
            },

        ],
        pretest:[
            {
                id: 1,
                Question: "What is the percentage of females and males using tobacco in India? (NFHS-5)",
                a: "Females 0.6% and Males 1.1%",
                b: "Females 0.3% and Males 1.9%",
                c: "Females 0.1:% and Male1.6%",
                d: "None of the above",
                correct: "b",
            },
            {
                id: 2,
                Question: "Why should we focus on substance misuse during adolescence? ",
                a: "To reduce the cost to individual, family, community, and health system for treatment of diseases arising due to substance misuse",
                b: "To protect against alcohol, tobacco, and other drugs (ATOD) and other high risk behavioural and addictive disorders",
                c: "To protect against alcohol use disorder (AUD)",
                d: "To help the adolescents delay early initiation of harmful substances ",
                correct: ["a","b"],
            },
            {
                id: 3,
                Question: "What is the aim of Rashtriya Kishor Swasthya Karyakram (RKSK) for adolescence regarding substance misuse?",
                a: "To prevent and manage harmful use of substances in adolescents, and the subsequent negative consequences",
                b: "To provide education and health to the adolescence",
                c: "To make adolescence aware of their rights",
                d: "All of the above ",
                correct: "a",
            },
            {
                id: 4,
                Question: "What do you understand by substance misuse withdrawal?",
                a: "Propel towards a better demeanour ",
                b: "Go on to get a better education",
                c: "Physical and/or mental symptoms that occur after stopping or reducing the intake of the misused substance",
                d: "All of the above ",
                correct: "c",
            },
            {
                id: 5,
                Question: "What do you understand by substance misuse continuum or stages of substance misuse",
                a: "Occasional users, Entertainment users, Regular users ",
                b: "Experiment users, Occasional users, Regular users",
                c: "Occasional users, Family users, Experiment users",
                d: "None of these ",
                correct: "b",
            },
            {
                id: 6,
                Question: "What are the protective factors against substance misuse for adolescents? ",
                a: "Good emotional support at home & positive role models ",
                b: "Reinforcement from academic success & healthy peer engagement",
                c: "High self-esteem & good social skills",
                d: "Connectedness to community & opportunity for leisure and positive cultural involvementNone of these ",
                e: "All of the above ",
                correct: "e",
            },
            {
                id: 7,
                Question: "What are the factors that increase risk of substance misuse? ",
                a: "Greater impulsivity and difficulty in putting off immediate gratification, poorer ability to sustain motivation, greater procrastination ",
                b: "Greater fluctuation of moods, greater rejection, sensitive dysphoria/anger",
                c: "Poorer ability to empathize or understand others’ thought processes",
                d: "None of these  ",
                correct: ["a","b","c"],
            },
            {
                id: 8,
                Question: "Which of these programmes and laws are for substance misuse?",
                a: "Drug De-Addiction Programme of the Ministry of Health and Family Welfare",
                b: "Narcotic Drugs and Psychotropic Substances (NDPS) Act, 1985 (Amendment in 1988)",
                c: "Rashtriya Kishor Swasthya Karyakram (National Adolescent Health Programme)",
                d: "National Action Plan for Drug Demand Reduction, MSJE, GoI of India  ",
                e: "The National Tobacco Control Programme ",
                f: "All of the above ",
                correct: "f",
            },
            {
                id: 9,
                Question: "What is the WHO-ASSIST tool used for? ",
                a: "Prevention and Control of substance misuse ",
                b: "Screening and Assessment of substance misuse",
                c: "Motivate to overcome drugs and other harmful substances ",
                d: "None of these  ",
                correct: "b",
            },
            {
                id: 10,
                Question: "In which facilities referral for substance misuse treatment available? ",
                a: "Adolescent Friendly Health Centres (AFHCs) ",
                b: "District Mental Health Programme",
                c: "Drug Dependence Treatment Centres ",
                d: "Community-based organizations or NGOs ",
                e: "All of the above ",
                correct: "e",
            },
        ],
    },
    {
        id: 2,
        title: "Nutrition and Anaemia in Adolescents",
        status:1,
        lessons:[
            {
                id: 1,
                Title: "Intro and Section 1 Understanding Nutrition and Malnutrition",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/829786674?h=a31050dd14",
                lockId:18,
                activities:[
                    {
                        id: 1,
                        Title: "Activity 1: True and False",
                        URL: "https://rkskmamta.in/activities/module2/Section_1",
                        Type: "Activity",
                        lockId:19,
                    },
                ],
            },
            {
                id: 2,
                Title: "Section 2 Nutrition and Malnutrition during Adolescence",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/829426316?h=0164e44a12",
                lockId:20,
                activities:[
                    {
                        id:2,
                        Title: "Link to suggested answers (Case studies of Raju and Rajiv)",
                        Type: "Page",
                        Story:"rajurajiv",
                        URL:"",
                        lockId:21,

                    },
                    {
                        id: 3,
                        Title: "Activity 2 : Drag and drop",
                        URL: "https://rkskmamta.in/activities/module2/Section_2",
                        Type: "Activity",
                        lockId:22,
                    },
                ]
            },
            {
                id: 3,
                Title: "Section 3 Anaemia during Adolescence",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/829463335?h=56bb92ab32",
                lockId: 23,
                activities:[
                    {
                        id:1,
                        Title: "Link to suggested answers (Role play Sumit)",
                        Type: "Page",
                        Story:"sumit",
                        URL:"",
                        lockId:24,

                    },
                    {
                        id: 2,
                        Title: "Activity 3 : True and False",
                        URL: "https://rkskmamta.in/activities/module2/Section_3",
                        Type: "Activity",
                        lockId:25,
                    },
                ]
            },
            {
                id: 4,
                Title: "Section 4 Part 1 Role of Medical Officers",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/829463177?h=56bb92ab32",
                lockId: 26,
                activities:[]
            },
            {
                id: 3,
                Title: "Section 4 Part 2 Divya's Story",
                Type: "Vimeo",
                URL: "https://player.vimeo.com/video/829473325?h=56bb92ab32",
                lockId: 27,
                activities:[
                    {
                        id:1,
                        Title: "Link to suggested answers (Story of Divya)",
                        Type: "Page",
                        Story:"divya",
                        URL:"",
                        lockId:28,

                    },
                    {
                        id: 2,
                        Title: "Activity 4 : True or False",
                        URL: "https://rkskmamta.in/activities/module2/Section_4",
                        Type: "Activity",
                        lockId:29,
                    },
                ]
            },

        ],
        pretest:[
            {
                id: 1,
                Question: "Why do adolescents have high nutritional requirements? (multiple choice possible)",
                a: "Because adolescence is a phase of rapid growth and development",
                b: "Because nutrition is required for growth of body (as many changes happen in body at this age)",
                c: "Because there is higher need of iron (especially girls)",
                d: "None of the above",
                correct: ["a","b","c"],
            },
            {
                id: 2,
                Question: "What factors influence nutrition?",
                a: "Food habits, customs, beliefs, traditions and attitudes are passed down in families (cultural practices) ",
                b: "Poverty, lack of knowledge regarding nutritive value of foods, lack of awareness regarding locally available and pocket-friendly foods (socio-cultural factors)",
                c: "Girls may suffer from dietary restrictions during menstruation (gender issues)",
                d: "All of the above",
                correct: "d",
            },
            {
                id: 3,
                Question: "Why anaemia is concern in adolescence?",
                a: "Stunted growth",
                b: "Diminished concentration",
                c: "Poor school performance",
                d: "Low birth weight babies at time of birth",
                e: "All of the above",
                correct: "e",
            },
            {
                id: 4,
                Question: "What is the percentage of anaemia among women aged 15-19 years I Himachal Pradesh (NFHS-5) ",
                a: "53.2",
                b: "35.4 ",
                c: "12.9",
                d: "Dont Know",
                correct: "a",
            },
            {
                id: 5,
                Question: "What is the percentage of women aged 15-49 years who are overweigh ",
                a: "30.4",
                b: "28.4",
                c: "10.9",
                d: "Don’t know ",
                correct: "a",
            },
            {
                id: 6,
                Question: "What is the purpose of WIFS programme?",
                a: "To address anaemia among adolescents ",
                b: "To supplement nutrition ",
                c: "Both a and b",
                correct: "c",
            },
            {
                id: 7,
                Question: "Which platforms should be used to create awareness in community about factors contributing to Malnutrition and Anaemia?",
                a: "Surveillance",
                b: "Adolescent Friendly Health Clinics (AFHC)",
                c: "Adolescent Health and Wellness Day (AH&WD)",
                d: "B and C",
                e: "All of the above ",
                correct: "d",
            },
            {
                id: 8,
                Question: "What is the significance of a healthy eating pyramid?",
                a: "The food pyramid demonstrates food groups",
                b: "The food pyramid shows which foods should be consumed in what quantities ",
                c: "It is important to understand which food groups help protect from diseases ",
                d: "All of the above ",
                correct: "d",
            },
            {
                id: 9,
                Question: "Micronutrient deficiency is the leading underlying cause of?",
                a: "Global burden of disease",
                b: "Global contributor to morbidity among adolescent mothers",
                c: "Global contributor to mortality among adolescent mothers ",
                d: "only 'a'",
                correct: "d",
            },
            {
                id: 10,
                Question: "What is the role of Medical Officers in improving nutritional status and anaemia prevention of adolescents?",
                a: "Coach paramedical staff to recognize and respond to nutritional concerns",
                b: "Conduct outreach sessions to educate the adolescents on importance of nutrition",
                c: "Work with parents and families to promote healthy eating, hygienic cooking practices and prevent anaemia",
                d: "All of the above ",
                correct: "d",
            },
        ],
    },
    {
        id: 3,
        title: "Nutrition and Anaemia in Adolescents",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 4,
        title: "Promoting Healthy Lifestyles - Prevention of Non-Communicable Diseases (NCDs) amongst Adolescents",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 5,
        title: "Adolescent Mental Health",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 6,
        title: "Sexual and Reproductive Health during Adolescence",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 7,
        title: "Prevention and Management of Substance Misuse",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 8,
        title: "Prevention and Redressal of Injuries and Violence amongst Adolescents",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 9,
        title: "Promotion of Safe Use of Internet, Gadgets and Social Media",
        status: 0,
        lessons: [],
        pretest:[]
    },
    {
        id: 10,
        title: "COVID-19 Pandemic",
        status: 0,
        lessons: [],
        pretest:[]
    }
]
*/
import React, {useEffect, useState} from 'react';
import API from "../Queries/API";

const Lessons = (course_id=1) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'view',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        setIsLoading(true)
        fetch(`${API.Lessons}?course_id=${course_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log({serverLessons:result})
                const newData = [...result]
                setData(newData)
            })
            .catch(error => console.log('error', error)).finally(()=>{
            setIsLoading(false)
        });

    },[])

    return {data, error, isLoading};
};

export default Lessons
