/*
export default [
    {id:1,Title:"Training on AFHS for Medical Officers",Status: 1}
]

*/

import React, {useEffect, useState} from 'react';
import API from "../Queries/API";

const Courses = (course_id=1) => {
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'viewcourses',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        setIsLoading(true)
        fetch(API.Lessons, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({serverCourses:result})
                const newData = [...result]
                setData(newData)
            })
            .catch(error => {
                setError(error.response)
                console.log('error', error)
            }).finally(()=>{
            setIsLoading(false)
        });

    },[])

    return {data, error, isLoading};
};

export default Courses
