import React, {useEffect, useState} from 'react';
import $ from 'jquery';

const VideoPlayer = (Prop) => {
    const [videoId, setVideoId] = useState('https://player.vimeo.com/video/814593825')

    useEffect(()=>{
        // Prop
        console.log({vpProp:Prop})
        // console.log(Prop?.curLessons)
        // console.log(Prop?.curLesson)
        setVideoId(Prop?.curLesson?.URL)

    },[])
    useEffect(()=>{
        console.log({changed:videoId})
        $("#vimeoplayer").attr('src',videoId)
    },[videoId])

    return (
        <div className="cd " id="content">
            <div className="page-separator">
                <div className="page-separator__text">{Prop?.curLesson?.Title}</div>
            </div>

            <div className="card">
                <div className="embed-responsive embed-responsive-16by9" style={{backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundImage: `url("./assets/images/Spinner.gif")` }}>
                    {videoId &&  <iframe id="vimeoplayer" className="embed-responsive-item"
                        // src="title=0&amp;byline=0&amp;portrait=0"
                                         src={Prop?.curLesson?.URL}
                                         allow="autoplay; fullscreen; picture-in-picture"
                                              allowFullScreen></iframe>}

                </div>
            </div>
        </div>
    )
};

export default VideoPlayer;