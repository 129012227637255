import React, {useEffect, useState} from 'react';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Sidebar from "../Components/Sidebar";
import Overlay from "../Components/Overlay";
import API from "../Queries/API";
import alertify from "alertifyjs";
import $ from 'jquery'
// import Courses from "../DataFiles/Courses";
import useLessons from "../DataFiles/Lessons";
import useCourses from "../DataFiles/Courses";
import {Checker} from "../Queries/Sessions";
// import Lessons from "../DataFiles/Lessons";

const QuizScore = () => {
    const {data:Lessons, isLoading, error} = useLessons()
    const {data:Courses, isLoadingCourse, courseError} = useCourses()


    const [myResult, setMR] = useState({})
    const [ResultDetails, setRD] = useState([])
    const [ModTitle, setMT] = useState("")
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'getscores',
        });
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Scores, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({serverProgress:result})
               setMR(result?.data)
                console.log({RD:result?.detail})
                setRD(result?.detail)
            })
            .catch(error => console.log('error', error));


    },[])
    useEffect(()=>{
        $("#selmodule").val(1)
    },[Lessons])
    useEffect(()=>{
        // console.log("ss")
        Checker()
    },[])

    const [showMenu, setShowMenu] = useState(false)

    const getStatus = (j,Status=0)=>{
        if(j?.pretest && j?.posttest && Status){
            return ['Completed','bg-success']
        }else if(j?.pretest && Status){
            return ['In Progress','bg-warning']
        }
        return ["Pending",'bg-danger'];
    }







    return (
        <div>
            {showMenu && <Overlay showMenu={setShowMenu}/>}
        <div className="layout-sticky-subnav layout-default ">

        <div className="preloader">
            <div className="sk-double-bounce">
                <div className="sk-child sk-double-bounce1"></div>
                <div className="sk-child sk-double-bounce2"></div>
            </div>
        </div>

        <div className="mdk-header-layout js-mdk-header-layout">

            <Header showMenu={setShowMenu}/>
            <div className="mdk-header-layout__content page-content ">

                <div className="pt-32pt">
                    <div
                        className="container page__container d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
                        <div className="flex d-flex flex-column flex-sm-row align-items-center">


                            <div className="mb-24pt mb-sm-0 mr-sm-24pt">
                                <h2 className="mb-0">Modules Score</h2>
                                <ol className="breadcrumb p-0 m-0">
                                    <li className="breadcrumb-item"><a href="./dashboard">Home</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                       Modules Score
                                    </li>
                                </ol>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="container page__container page-section">
                    <div className="">
                        <div className="col-12 col-md-6 mb-3">
                            <label className="form-label">All Courses</label>
                            <select className="form-control" id="selmodule" placeholder="Education Qualified" onChange={(v)=>{
                                console.log({v})
                                if(v?.target?.value != 1){
                                    alertify.alert("E-Learning","No record available for module "+v?.target?.value)
                                    $("#selmodule").val(1)
                                    // return;
                                }
                                let tx = Courses?.find(x=>x.id==v.target.value).Title
                                console.log(tx)
                                setMT(tx)
                            }}>
                                <option>Select Course</option>
                                {
                                    Courses?.map((oneCourse)=>{
                                        return (<option value={oneCourse?.id}>{oneCourse?.Title}</option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="page-separator">
                        <div className="page-separator__text">{ModTitle}</div>
                    </div>

                    <div className="card dashboard-area-tabs p-relative o-hidden mb-lg-32pt">
                        <div className="card-header p-0 nav">
                            <div className="row no-gutters" role="tablist">
                                <div className="col-auto">
                                    <a href="#" data-toggle="tab" role="tab" aria-selected="true"
                                       className="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start active">
                                        <span className="flex d-flex flex-column">
                                        <strong className="card-title">{ModTitle}</strong>
                                    </span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table mb-0 thead-border-top-0 table-nowrap">
                                <thead>
                                <tr>
                                    <th style={{width:150}}>
                                        <a href="#" className="sort"
                                           data-sort="js-lists-values-project">Module</a>
                                    </th>
                                    <th style={{width:48}}>
                                        <a href="#" className="sort"
                                           data-sort="js-lists-values-status">Status</a>
                                    </th>
                                    <th style={{width:48}}>
                                        <a href="#" className="sort"
                                           data-sort="js-lists-values-budget">Pre-Test</a>
                                    </th>
                                    <th style={{width:48}}>
                                        <a href="#" className="sort " data-sort="js-lists-values-date">Post-Test</a>
                                    </th>
                                    <th style={{width:24}}></th>
                                </tr>
                                </thead>
                                <tbody className="list" id="projects">
                             {/*   <tr>


                                    <td>
                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace: 'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span className="avatar-title rounded bg-primary text-white">MD1</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module 1</strong></small>

                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">{getStatus(myResult)[0]}</small>
                                            <span className={`indicator-line rounded ${getStatus(myResult)[1]}`}></span>
                                        </div>
                                    </td>


                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget">{myResult?.pretest ? <strong>{myResult?.pretest}/{myResult?.pretestcnt}</strong> : <strong>Not Taken</strong>}</small>
                                            <small className="js-lists-values-budget" style={{display: myResult?.pretest ? 'block' :'none' }}><a href="./pretest-result"
                                                                                         style={{color: 'blue'}}
                                            >View
                                                Result</a></small>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date">{myResult?.posttest ? <strong>{myResult?.posttest}/{myResult?.posttestcnt}</strong> : <strong>Not Taken</strong>}</small>
                                            <small className="js-lists-values-budget"  style={{display: myResult?.posttest ? 'block' :'none' }}><a href="./posttest-result"
                                                                                          style={{color: 'blue'}}
                                            >View
                                                Result</a></small>
                                        </div>
                                    </td>
                                </tr>

*/}
                                {
                                    Lessons?.map((oneLesson,i)=>{
                                        return (


                                            <tr key={i}>
                                                <td>
                                                    <div className="media flex-nowrap align-items-center"
                                                         style={{whiteSpace: 'nowrap'}}>
                                                        <div className="avatar avatar-sm mr-8pt">
                                                            <span className="avatar-title rounded bg-primary text-white">MD1</span>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="d-flex flex-column">
                                                                <small className="js-lists-values-project"><strong>{oneLesson?.title}</strong></small>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column">
                                                        <small className="js-lists-values-status text-50 mb-4pt">{getStatus(myResult,oneLesson?.status)[0]}</small>
                                                        <span className={`indicator-line rounded ${getStatus(myResult,oneLesson?.status)[1]}`}></span>
                                                    </div>
                                                </td>


                                                <td>
                                                    <div className="d-flex flex-column">
                                                       {/* <small className="js-lists-values-budget">
                                                            {myResult?.pretest && oneLesson?.status ? <strong>{myResult?.pretest}/{myResult?.pretestcnt}</strong> : <strong>Not Taken</strong>}</small>*/}
                                                        {
                                                            ResultDetails.find((oneDetail)=>{
                                                                console.log({oneDetail,oneLesson})
                                                                return oneDetail?.module_id == oneLesson?.id
                                                            })?.pretest || "Not Taken"
                                                        }
                                                        {ResultDetails?.find((oneDetail)=>{
                                                            return oneDetail?.module_id == oneLesson?.id
                                                        })?.pretest && "/10"}


                                                        <small className="js-lists-values-budget" style={{display: myResult?.pretest && oneLesson?.status ? 'block' :'none' }}><a href="./pretest-result"
                                                                                                                                                             style={{color: 'blue'}}
                                                        >View
                                                            Result</a></small>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="d-flex flex-column">
                                                      {/*  <small className="js-lists-values-date">{myResult?.posttest && oneLesson?.status ? <strong>{myResult?.posttest}/{myResult?.posttestcnt}</strong> : <strong>Not Taken</strong>}</small>*/}

                                                        {
                                                            ResultDetails.find((oneDetail)=>{
                                                                console.log({oneDetail,oneLesson})
                                                                return oneDetail?.module_id == oneLesson?.id
                                                            })?.posttest || "Not Taken"
                                                        }
                                                        {ResultDetails?.find((oneDetail)=>{
                                                            return oneDetail?.module_id == oneLesson?.id
                                                        })?.posttest && "/10"}

                                                        <small className="js-lists-values-budget"  style={{display: myResult?.posttest && oneLesson?.status ? 'block' :'none' }}><a href="./posttest-result"
                                                                                                                                                               style={{color: 'blue'}}
                                                        >View
                                                            Result</a></small>
                                                    </div>
                                                </td>
                                            </tr>



                                            /*<tr key={i} style={{backgroundColor: oneLesson?.status ? '#b0df93' : 'rgb(201, 196, 196)'}}>

                                                <td>{oneLesson?.id}</td>
                                                <td width={200}>{oneLesson?.title}</td>
                                                <td style={{color: oneLesson?.status ? 'blue' : 'red'}}>{oneLesson?.status ? 'Completed' : 'Pending'}</td>
                                                <td>0m 0s</td>

                                            </tr>*/

                                        )
                                    })
                                }



{/*
                                <tr>

                                    <td>
                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span className="avatar-title rounded bg-accent text-white">MD2</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module 2</strong></small>

                                                </div>
                                            </div>
                                        </div>
                                    </td>


                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>
                                <tr>

                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span className="avatar-title rounded bg-warning text-white">MD3</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module 3</strong></small>

                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>


                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-primary text-white">MD4</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        4</strong></small>

                                                </div>
                                            </div>
                                        </div>

                                    </td>


                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>


                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>

                                </tr>
                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 5</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        5</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>

                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 6</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        6</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>

                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 7</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        7</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>

                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 8</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        8</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>

                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 9</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        9</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>

                                <tr className="selected">
                                    <td>

                                        <div className="media flex-nowrap align-items-center"
                                             style={{whiteSpace:'nowrap'}}>
                                            <div className="avatar avatar-sm mr-8pt">
                                                <span
                                                    className="avatar-title rounded bg-accent text-white">MD 10</span>
                                            </div>
                                            <div className="media-body">
                                                <div className="d-flex flex-column">
                                                    <small className="js-lists-values-project"><strong>Module
                                                        10</strong></small>


                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-status text-50 mb-4pt">Pending</small>
                                            <span className="indicator-line rounded bg-accent"></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-budget"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>


                                        </div>
                                    </td>

                                    <td>
                                        <div className="d-flex flex-column">
                                            <small className="js-lists-values-date"><strong>Not Taken</strong></small>
                                            <small className="js-lists-values-budget"><a href="#"
                                                                                         style={{color: 'grey',display:'none'}}>View
                                                Result</a></small>

                                        </div>
                                    </td>
                                </tr>*/}

                                </tbody>
                            </table>

                        </div>

                        <div className="card-footer p-8pt">

                            <ul className="pagination justify-content-start pagination-xsm m-0">
                                <li className="page-item disabled">
                                    <a className="page-link" href="#" aria-label="Previous">
                                        <span aria-hidden="true" className="material-icons">chevron_left</span>
                                        <span>Prev</span>
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Page 1">
                                        <span>1</span>
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Page 2">
                                        <span>2</span>
                                    </a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="#" aria-label="Next">
                                        <span>Next</span>
                                        <span aria-hidden="true" className="material-icons">chevron_right</span>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>


            </div>

           <Footer/>

        </div>


       {/* <script src="assets/vendor/jquery.min.js"></script>

        <script src="assets/vendor/popper.min.js"></script>
        <script src="assets/vendor/bootstrap.min.js"></script>
            <script src="assets/vendor/perfect-scrollbar.min.js"></script>

        <script src="assets/vendor/dom-factory.js"></script>

        <script src="assets/vendor/material-design-kit.js"></script>

        <script src="assets/vendor/fix-footer.js"></script>

        <script src="assets/js/app.js"></script>


        <script src="assets/vendor/jquery.bootstrap-touchspin.js"></script>
        <script src="assets/js/touchspin.js"></script>

        <script src="assets/vendor/flatpickr/flatpickr.min.js"></script>
        <script src="assets/js/flatpickr.js"></script>

        <script src="assets/vendor/moment.min.js"></script>
        <script src="assets/vendor/daterangepicker.js"></script>
        <script src="assets/js/daterangepicker.js"></script>

        <script src="assets/vendor/jquery.mask.min.js"></script>

        <script src="assets/vendor/quill.min.js"></script>
        <script src="assets/js/quill.js"></script>

        <script src="assets/vendor/select2/select2.min.js"></script>
        <script src="assets/js/select2.js"></script>*/}

        </div>
        </div>

);
};

export default QuizScore;