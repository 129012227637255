import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Footer from "../Components/Footer";
import HeaderOut from "../Components/HeaderOut";
import API from "../Queries/API";
import alertify from "alertifyjs";
import $ from "jquery";
import moment from "moment/moment";

const ExpertQuestions = () => {
    const [expertProfile, setExpertProfile] = useState({})
    const [Questions, setQuestions] = useState([])
    const [selectedQuestion, setSQ] = useState({})
    const [isLoading, setIL] = useState(false)

    useEffect(()=>{


        let m = JSON.parse(localStorage.userData);
        if(m){
            setExpertProfile(m)
        }else{
            window.location.href="./expert-login"
        }



        let data = {
            type:'expert',
            token: localStorage.usertoken,
            perform:'loadfaqs'
        }

        fetch(API.Experts,{
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrerPolicy:'no-referrer',
            body: JSON.stringify(data)
        }).then((response)=> response.json())
            .then((rsp)=>{
                console.log({rsp})
                setQuestions(rsp)
            })
            .finally(()=>{
                // setL(false)
            })

    },[])

    const handleSubmit = ()=>{
        if($("#form11").val() == ""){
            alertify.alert("E-Learning","Your answer cannot be empty");
            return;
        }
        setIL(true);
        var myHeaders = new Headers();
        myHeaders.append("authorization", "Bearer "+localStorage.usertoken);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "perform": 'replyquestion',
            "expert":JSON.parse(localStorage.userData).uid,
            "token":localStorage.usertoken,
            "questionId":selectedQuestion.id,
            "answer":$("#form11").val(),
        });
        console.log({raw})
        // return;
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API.Experts, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({experts:result})
                alertify.alert("E-Learning",result.msg).set('onok',()=>window.location.reload())
                // $("#form10").val("");


            })
            .catch(error => console.log('error', error))
            .finally(()=>{
                setIL(false)
            });


    }





    return (
        <div className="layout-sticky-subnav layout-default ">

            <div className="preloader">
                <div className="sk-double-bounce">
                    <div className="sk-child sk-double-bounce1"></div>
                    <div className="sk-child sk-double-bounce2"></div>
                </div>
            </div>
            <HeaderOut expert={true}/>

            <div className="mdk-header-layout js-mdk-header-layout">

                <div className="mdk-header-layout__content Xpage-content" style={{paddingBottom: '80px'}}>

                    <div className="pt-132pt  pb-32pt">
                        <div className="container page__container">





                            <div>
                                <div className="row">

                                    <div className="col-lg-12">
                                        <div className="page-section">
                                            <h4>Questions Submitted to {expertProfile?.name}</h4>

                                            <div className="Xcard--connect pb-32pt pb-lg-64pt">
                                                <div className="card o-hidden mb-0">

                                                    {
                                                        Questions.map((oneQuestion,I)=>{
                                                            return (
                                                                <div>
                                                                    <div className="card-header bg-transparent forcewhite" style={{padding: 5}}>
                                                                        <h6 className="m-0 " style={{color: "white"}}>Date:{oneQuestion?.askdate && moment(oneQuestion?.askdate).format('Do MMM, YY')}</h6>
                                                                        <h6 className=" mb-0" style={{color: "white"}}>TOPIC: {oneQuestion.topic} </h6>
                                                                        <h6 className=" mb-0" style={{color: "white"}}>User: {oneQuestion?.user_name} </h6>


                                                                    </div>

                                                                    <div className="list-group list-group-flush">
                                                                        <div className=" card list-group-item p-3">
                                                                            <div className="row align-items-start">
                                                                                <div className="  col-sm-9 mb-8pt mb-md-0">
                                                                                    <div className="mediaX align-items-center">
                                                                                        <div className=" media-left mr-12pt">
                                                                                            <h6 style={{fontSize: 17}}>Question: {oneQuestion?.question}</h6>
                                                                                        </div>
                                                                                        <div className=" media-left mr-12p">
                                                                                            <p>
                                                                                                <strong>Answer:</strong>
                                                                                                {oneQuestion.answer || <div className="text-warning">Awaiting Reply</div>}
                                                                                            </p>
                                                                                            <div>
                                                                                                <button className={oneQuestion.answer ? "btn btn-info" : "btn btn-primary"}
                                                                                                        data-toggle="modal" data-target="#myModal"
                                                                                                        rel=""
                                                                                                        role="button" tabIndex="0"
                                                                                                        onClick={()=>{
                                                                                                            setSQ(oneQuestion)
                                                                                                        }}><i className ="fa fa-reply"></i> {oneQuestion.answer ? 'Modify Answer' : 'Reply Question'}</button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="col-md-3 mb-8pt mb-md-0 d-flex flex-column align-items-center justify-content-center"
                                                                                    style={{marginTop:67,paddingLeft: 89}}>
                                                                                    <h6 className="m-0">BY {expertProfile.name} <br/> Date:{oneQuestion?.answer_date && moment(oneQuestion?.answer_date).format('Do MMM, YY')}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                    }

{/*

                                                    <div className="card-header bg-transparent forcewhite" style={{padding: 5}}>
                                                        <h6 className="m-0 " style={{color: "white"}}>Date: 6/05/2023</h6>
                                                        <h6 className=" mb-0" style={{color: "white"}}>TOPIC: Topic selected by user </h6>


                                                    </div>

                                                    <div className="list-group list-group-flush">
                                                        <div className=" card list-group-item p-3">
                                                            <div className="row align-items-start">
                                                                <div className="  col-md-9 mb-8pt mb-md-0">
                                                                    <div className="media align-items-center">
                                                                        <div className=" media-left mr-12pt">
                                                                            <h6 style={{fontSize: 17}}>Question:Lorem ipsum dolor sit
                                                                                amet consectetur
                                                                                adipisicing elit. Labore, laboriosam ?</h6>
                                                                        </div>
                                                                        <div className=" media-left mr-12p">
                                                                            <p><strong>Answer:</strong>Lorem ipsum dolor sit amet
                                                                                consectetur adipisicing
                                                                                elit. Illo ut omnis nihil nam ex rerum error
                                                                                voluptas, quibusdam est ullam quidem incidunt ipsum
                                                                                repellat atque laboriosam illum autem necessitatibus
                                                                                voluptates?</p>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="col-md-3 mb-8pt mb-md-0 d-flex flex-column align-items-center justify-content-center"
                                                                    style={{marginTop:67,paddingLeft: 89}}>
                                                                    <h6 className="m-0">BY Dr. Abhinav Kumar <br/> Date:6/05/2023</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="card-header bg-transparent forcewhite" style={{padding: 5}}>
                                                        <h6 className="m-0 " style={{color: "white"}}>Date: 6/05/2023</h6>
                                                        <h6 className=" mb-0" style={{color: "white"}}>TOPIC: Topic selected by user
                                                        </h6>


                                                    </div>

                                                    <div className="list-group list-group-flush">
                                                        <div className="list-group-item p-3">
                                                            <div className="row align-items-start">
                                                                <div className="col-md-9 mb-8pt mb-md-0">
                                                                    <div className="media align-items-center">
                                                                        <div className="media-left mr-12pt">
                                                                            <h6 style={{fontSize: 17}}>Question:Lorem ipsum dolor sit
                                                                                amet consectetur
                                                                                adipisicing elit. Labore, laboriosam ?</h6>
                                                                        </div>
                                                                        <div className="media-left mr-12p">
                                                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing
                                                                                elit. Illo ut omnis nihil nam ex rerum error
                                                                                voluptas, quibusdam est ullam quidem incidunt ipsum
                                                                                repellat atque laboriosam illum autem necessitatibus
                                                                                voluptates?</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className=" col-md-3 mb-8pt mb-md-0 d-flex flex-column align-items-center justify-content-center"
                                                                    style={{marginTop:67,paddingLeft: 89}}>
                                                                    <h6 className="m-0">BY Dr. Abhinav Kumar <br/> Date:6/05/2023</h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>*/}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>









                        </div>

                    </div>

                </div>


                <Footer/>

            </div>

            <div className="modal fade" id="myModal" role="dialog">
                <div className="modal-dialog">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Answer the Question</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>

                        </div>
                        <div className="modal-body">


                            <div className="col-lg-10 p-0 mx-auto">
                                <div className="row">
                                    <div className="col-md-12 mb-24pt mb-md-0">
                                        <form action="#">
                                            <div className="form-group">
                                                <input type="text" readOnly={true} value={selectedQuestion?.topic} className="form-control" />
                                            </div>
                                            <div className="form-group">

                                                <label className="form-label" htmlFor="custom-select">Question</label>
                                                <textarea id="form10" className="md-textarea form-control"
                                                          rows="3" disabled={isLoading} value={selectedQuestion.question} readOnly={true}></textarea>

                                            </div>
                                            <div className="form-group">

                                                <label className="form-label" htmlFor="custom-select">Answer</label>
                                                <textarea id="form11" className="md-textarea form-control"
                                                          rows="3"  disabled={isLoading} ></textarea>

                                            </div>

                                            <button onClick={()=>handleSubmit()} disabled={isLoading} className="btn btn-primary"><i className={isLoading ? "fa-spinner fa-spin" : ""}></i>{isLoading ? 'Submitting...' : 'Submit'}</button>
                                        </form>
                                    </div>

                                </div>
                            </div>






                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default ExpertQuestions;